import React, { useState } from 'react';
import styled from 'styled-components';

import Icon from 'components/elements/Icon';
import HeaderWrapper from './HeaderWrapper';
import HeaderItem from './HeaderItem';
import ArrowContainer from './ArrowContainer';
import FileUploaderIconComponent from 'shared/FileUploader/components/FileUploaderIconComponent';

export interface TabItem {
  header: React.FC<{ active?: boolean }>;
  body: React.FC<{ active?: boolean }>;
  isActiveCallback?: (active: boolean) => void;
}

const TabContainer = styled.div`
  width: 100%;
`;

const LinkWrapper = styled.a`
  margin-left: 0.5em;
  width: 60px;
  height: 46px;
`;

interface TabWrapperProps {
  tabs: TabItem[];
  withArrows?: boolean;
  renderWithIndex?: number;
  uploadUrl: string;
  downloadUrl: string;
  children?: React.ReactNode;
}

function TabWrapper({
  tabs,
  withArrows,
  renderWithIndex,
  uploadUrl,
  downloadUrl,
  children,
}: TabWrapperProps) {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    renderWithIndex ? renderWithIndex : 0
  );

  const setActiveIndex = (index: number, callback?: TabItem['isActiveCallback']) => {
    callback && callback(index === activeTabIndex);
    setActiveTabIndex(index);
  };

  const Body = tabs[activeTabIndex].body;

  return (
    <TabContainer>
      <HeaderWrapper>
        {tabs.map(({ header: Header }, index) => (
          <React.Fragment key={index}>
            <HeaderItem
              key={index}
              onClick={() => setActiveIndex(index)}
              active={index === activeTabIndex}
              withArrows={withArrows}
            >
              <Header active={index === activeTabIndex} />
            </HeaderItem>
            {withArrows && index !== tabs.length - 1 && (
              <ArrowContainer>
                <Icon icon='arrow-right-alt' color='#006F94'></Icon>
              </ArrowContainer>
            )}
          </React.Fragment>
        ))}

        {children}

        <HeaderItem>
          <FileUploaderIconComponent
            uploadUrl={uploadUrl}
            onDoneCallback={(success) => console.log(success)}
            onErrorCallback={(error) => console.log(error)}
          />
          <LinkWrapper href={downloadUrl} className='button button--medium-blue'>
            <Icon icon='download' color='#FFFFFF' />
          </LinkWrapper>
        </HeaderItem>
      </HeaderWrapper>

      <Body active={true} />
    </TabContainer>
  );
}

export default TabWrapper;
