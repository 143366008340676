import ContentHeader from 'components/elements/ContentHeader';
import React, { useState } from 'react';
import AuthenticityToken from 'shared/AuthenticityToken/AuthenticityToken';
import InventoryTable from './InventoryTable';
import useStores from 'utils/useStores';
import { observer } from 'mobx-react';
import MassChangesOverlay from './MassChangesOverlay';
import Pagynation from 'shared/Pagynation';
import Routes from 'routes';

const InventoryReconcilation = (): JSX.Element => {
  const { inventoryReconciliationStore } = useStores();
  const { selectedInventories, pagy } = inventoryReconciliationStore;

  const [manyChangesOverlay, setManyChangesOverlay] = useState(false);
  return (
    <div className='content'>
      <AuthenticityToken />
      <ContentHeader title={I18n.translate('headlines.inventory_conciliation')}>
        <button
          type='button'
          disabled={selectedInventories.length === 0}
          className='button button--green'
          onClick={() => setManyChangesOverlay(true)}
        >
          {I18n.translate('buttons.concile')}
        </button>
      </ContentHeader>
      <InventoryTable />
      <Pagynation pagy={pagy} url={Routes.inventory_reconciliations_path} />
      {manyChangesOverlay && (
        <MassChangesOverlay setManyChangesOverlay={setManyChangesOverlay} />
      )}
    </div>
  );
};

export default observer(InventoryReconcilation);
