import React, { useMemo, useState } from 'react';
import Overlay from 'shared/Overlay';
import InputGroupComponent from 'components/groups/InputGroup';
import styled from 'styled-components';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import useStores from 'utils/useStores';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';
const InventoryChangeOverlayBody = styled.div`
  display: grid;
  grid-gap: 0 1.625em;
`;

interface ManyChangesOverlayProps {
  setManyChangesOverlay: (value: boolean) => void;
}

const ManyChangesOverlay = ({
  setManyChangesOverlay,
}: ManyChangesOverlayProps): JSX.Element => {
  const { inventoryReconciliationStore } = useStores();
  const { selectedInventories } = inventoryReconciliationStore;
  const [observations, setObservations] = useState('');

  const user = useMemo(() => {
    return selectedInventories[0].user;
  }, []);

  const handleApproveRejectClick = async (action: string) => {
    try {
      await customFetch(
        Routes.api_v1_inventory_reconciliations_reconcile_inventories_path(),
        {
          data: inventoryReconciliationStore.serialize(
            selectedInventories,
            action,
            observations
          ),
        },
        'POST'
      );

      window.location.href = window.location.pathname;
    } catch (e) {
      console.log(e);
    }
  };

  const onCancelClick = () => {
    setManyChangesOverlay(false);
  };
  return (
    <Overlay
      title={`${I18n.translate('inventory_reconciliations.form.mass_changes')}`}
      primaryDisabled={!observations}
      secondaryDisabled={!observations}
      primaryText={`${I18n.translate('buttons.approve')}`}
      secondaryText={I18n.translate('buttons.reject')}
      thirdText={I18n.translate('buttons.close')}
      onPrimaryClick={() => handleApproveRejectClick('approve')}
      onSecondaryClick={() => handleApproveRejectClick('reject')}
      onThirdClick={onCancelClick}
      primaryClassName='button button--green'
      secondaryClassName='button button--red'
    >
      <InventoryChangeOverlayBody>
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.user')}
          disabled={true}
          value={user}
        />
        <TextAreaGroupComponent
          label={I18n.translate('inventory_reconciliations.form.observations')}
          value={observations}
          onChange={(e) => setObservations(e.target.value)}
        />
      </InventoryChangeOverlayBody>
    </Overlay>
  );
};

export default ManyChangesOverlay;
