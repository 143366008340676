import { action, computed, observable } from 'mobx';

import IFinalizeableResource from '../types/IFinalizeableResource';
import { FinalizableService } from 'exports/services/FinalizeService';
import FinalizeableDependency from './FinalizeableDependency';

export interface Finalizeable {
  finalized: boolean;
  isFinalizable: boolean;
}

abstract class FinalizeableResource<T extends IFinalizeableResource>
  implements Finalizeable
{
  @observable id?: number;
  @observable public abstract finalized: boolean;
  @observable private finalizeableDependency: FinalizeableDependency;

  constructor(attributes: T, protected httpLayerService: FinalizableService<T>) {
    this.updateAttributes(attributes);
  }

  @action public save = async (payload: T): Promise<void> => {
    if (this.id) {
      await this.update(this.id, payload);
    } else {
      await this.create(payload);
    }
  };

  @action public create = async (payload: T): Promise<void> => {
    const attributes = await this.httpLayerService.create(payload);

    this.updateAttributes(attributes);
  };

  @action public update = async (id: number, payload: T): Promise<void> => {
    const attributes = await this.httpLayerService.update(id, payload);

    this.updateAttributes(attributes);
  };

  @action public finalize = async (): Promise<void> => {
    if (this.id) {
      const { finalized } = await this.httpLayerService.finalize(this.id);

      this.finalized = finalized;
    }
  };

  @action public addDependency = (dependency: IFinalizeableResource): void => {
    if (!this.finalizeableDependency) {
      this.finalizeableDependency = new FinalizeableDependency();
    }

    this.finalizeableDependency.add(dependency);
  };

  @computed protected get dependenciesFinalized(): boolean {
    return this.finalizeableDependency.finalized;
  }

  public abstract get isFinalizable(): boolean;

  protected abstract updateAttributes(attributes: T);

  protected hasValidAttributeValue = (...attributes: string[]) => {
    return !attributes.find((attribute) =>
      [undefined, null, ''].includes(this[attribute])
    );
  };
}

export default FinalizeableResource;
