import { action, observable } from 'mobx';
import { SelectOption } from 'types/model/selectOption';
import { IField } from 'components/shared/form/IField';
import { AvailableCoffeeType } from 'opportunities/components/AvailableCoffee';

interface SelectedCustomer extends SelectOption {
  price_unit_currency?: string;
}

interface OpportunityStoreProps {
  fields: IField[];
  availableCoffee: AvailableCoffeeType[];
  selectedIcos: AvailableCoffeeType[];
}

class OpportunityStore {
  @observable public selectedCustomer?: SelectedCustomer;
  @observable public filteredCoffee: AvailableCoffeeType[] = [];
  @observable public originWarehouseId?: string;
  @observable public selectedIcos: AvailableCoffeeType[] = [];
  availableCoffee: AvailableCoffeeType[] = [];

  constructor({ fields, availableCoffee, selectedIcos }: OpportunityStoreProps) {
    const customerField = fields.find((field) => field.name === 'customer_id');
    if (customerField?.value) {
      this.selectedCustomer = customerField.options?.find(
        (option) => option.id === customerField.value
      );
    }

    this.originWarehouseId = fields.find(
      (field) => field.name === 'origin_warehouse_id'
    )?.value;
    this.setAvailableCoffee(availableCoffee, selectedIcos);
    this.setFilteredCoffee(this.originWarehouseId);
    this.selectedIcos = selectedIcos;
  }

  @action public setSelectedCustomer(customer?: SelectedCustomer) {
    this.selectedCustomer = customer;
  }

  @action public setOriginWarehouseId(warehouseId: string) {
    this.originWarehouseId = warehouseId;
    this.availableCoffee.forEach((c) => (c.checked = false));
    this.setFilteredCoffee(warehouseId);
  }
  @action setFilteredCoffee(origin_warehouse_id?: string) {
    if (origin_warehouse_id)
      this.filteredCoffee = this.availableCoffee.filter(
        (c) => c.origin_warehouse_id === Number(origin_warehouse_id)
      );
  }

  @action setAvailableCoffee(
    availableCoffee: AvailableCoffeeType[],
    selectedIcos?: AvailableCoffeeType[]
  ) {
    this.availableCoffee = availableCoffee;
    if (selectedIcos && selectedIcos.length > 0) {
      const icoIds = selectedIcos.map((ico) => ico.id);
      this.availableCoffee.forEach((ico) => {
        ico.checked = icoIds.includes(ico.id);
      });
    }
  }
}

export default OpportunityStore;
