import React from 'react';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { observer } from 'mobx-react';
import AvailableCoffee from 'opportunities/components/AvailableCoffee';
import { AvailableCoffeeType } from 'opportunities/components/AvailableCoffee';
import { IField } from 'components/shared/form/IField';
import InputGroupComponent from 'components/groups/InputGroup';
import { Grid } from 'shared/ScrollableTable';
import ToolTip from 'shared/ToolTip/components/ToolTip';
import { OpportunitySelectGroup } from '../OpportunityFields';
import useStores from 'utils/useStores';
interface OpportunitySpotCoffeeProps {
  onSelect: (AvailableCoffeeType) => void;
  disabled?: boolean;
  selectedSpotIcos: AvailableCoffeeType[];
  updateIcoUnits: (icoId: number, value: string) => void;
  estimatedUnitsField?: IField;
  units?: number;
  initialSelectedIcos: AvailableCoffeeType[];
  originWarehouseField?: IField;
  setSelectedSpotIcos: React.Dispatch<React.SetStateAction<AvailableCoffeeType[]>>;
}

const OpportunitySpotCoffee = ({
  onSelect,
  selectedSpotIcos,
  updateIcoUnits,
  disabled = false,
  initialSelectedIcos,
  originWarehouseField,
  setSelectedSpotIcos,
}: OpportunitySpotCoffeeProps) => {
  const { opportunityStore } = useStores();
  const originWarehouseId = opportunityStore.originWarehouseId;

  const destroyOpportunityIcos = () => {
    const selectedIcoIds = selectedSpotIcos.map((ico) => ico.id);
    const toDestroy = initialSelectedIcos.filter(
      (ico) => !selectedIcoIds.includes(ico.id)
    );

    return toDestroy.map((ico) => {
      return (
        <div key={`id-${ico.id}`}>
          <input
            type='hidden'
            name={`opportunity[opportunity_icos_attributes][][id]`}
            value={ico.opportunity_ico_id}
          />
          <input
            type='hidden'
            name={`opportunity[opportunity_icos_attributes][][_destroy]`}
          />
        </div>
      );
    });
  };

  return (
    <FieldSetWithTitle
      title={I18n.translate('opportunities.form.coffee')}
      disabled={disabled}
    >
      <Grid>
        <OpportunitySelectGroup
          field={originWarehouseField}
          value={originWarehouseId}
          onChange={(e) => {
            opportunityStore.setOriginWarehouseId(e);
            setSelectedSpotIcos([]);
          }}
        />
        {selectedSpotIcos.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Info</th>
                <th>Ico ID</th>
                <th>Estimated Units</th>
              </tr>
            </thead>
            <tbody>
              {selectedSpotIcos.map((coffee) => {
                return (
                  <tr key={coffee.id}>
                    <th
                      style={{
                        position: 'absolute',
                        boxShadow: 'unset',
                      }}
                    >
                      <ToolTip
                        title={`Additional Information`}
                        icon='info'
                        style={{
                          marginTop: '50%',
                          marginLeft: '50%',
                        }}
                        infos={[
                          {
                            label: I18n.translate('attributes.origin'),
                            value: coffee.origin_country,
                          },
                          {
                            label: I18n.translate('attributes.mark'),
                            value: coffee.mark,
                          },
                          {
                            label: I18n.translate('attributes.grade'),
                            value: coffee.quality,
                          },
                          {
                            label: I18n.translate('attributes.certificate'),
                            value: coffee.certificate || '-',
                          },
                          {
                            label: I18n.translate(
                              'opportunities.form.capacity_and_packaging'
                            ),
                            value: coffee.packagingType,
                          },
                        ]}
                      />
                    </th>
                    <th>
                      <InputGroupComponent
                        key={coffee.id}
                        className='align-center'
                        readOnly
                        value={coffee.ico_with_secondary_number || coffee.identifier.name}
                      />
                    </th>

                    <th>
                      {coffee.opportunity_ico_id && (
                        <input
                          type='hidden'
                          name={`opportunity[opportunity_icos_attributes][][id]`}
                          value={coffee.opportunity_ico_id}
                        />
                      )}
                      <input
                        type='hidden'
                        name='opportunity[opportunity_icos_attributes][][ico_id]'
                        value={coffee.id}
                      />
                      <InputGroupComponent
                        name={`opportunity[opportunity_icos_attributes][][units]`}
                        type='number'
                        min={1}
                        value={coffee.desired_units || 0}
                        max={coffee.available_units || 0}
                        append={
                          coffee.available_units ? `/${coffee.available_units}` : '0'
                        }
                        onChange={(e) => updateIcoUnits(coffee.id, e.target.value)}
                      />
                    </th>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : undefined}
        {!disabled && !!originWarehouseId && (
          <AvailableCoffee
            buttonTitle={
              selectedSpotIcos.length > 0
                ? I18n.translate('available_coffee_overlay.change_ico')
                : I18n.translate('available_coffee_overlay.see_available_coffee')
            }
            onSelect={onSelect}
            originWarehouseId={originWarehouseId}
          />
        )}
      </Grid>
      {destroyOpportunityIcos()}
    </FieldSetWithTitle>
  );
};

export default observer(OpportunitySpotCoffee);
