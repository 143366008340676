import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import SensorialAnalysisType from '../models/SensorialAnalysis';

import { COLORS } from 'constants/colors';
import Icon from 'components/elements/Icon';

import Badge from 'components/shared/Badge';
import BodyInput from './BodyInput';
import ConclusionInput from './ConclusionInput';
import CuppingSessionOverlay from './CuppingSessionOverlay';
import DefectInput from './DefectInput';
import FlavourInput from './FlavourInput';
import ObservationInput from './ObservationInput';
import OverallInput from './OverallInput';
import ScoreInput from './ScoreInput';
import AlternativeInput from './AlternativeInput';
import RoastInput from './RoastInput';

interface SensorialAnalysisProps {
  count: number;
  lotsCount: number;
  sensorialAnalysis: SensorialAnalysisType;
  cuppingSessionEnabled: boolean;
}

const SensorialAnalysis: React.FC<SensorialAnalysisProps> = ({
  lotsCount,
  count,
  sensorialAnalysis: {
    defects,
    descriptorBodies,
    descriptorFlavours,
    descriptorOveralls,
    descriptorRoasts,
    sample,
    alternatives,
    conclusion,
    completed,
    defectsSelected,
    score,
    badScore,
    setScore,
    setRejectGrade,
    observation,
    setObservation,
    quality,
    setQuality,
    markAsReject,
    cuppingTableNumber,
  },
  cuppingSessionEnabled,
}) => {
  // indexing for all used overlays
  let overlayIndex = 0;

  const renderSelectedFlavours = (
    <div>
      {descriptorFlavours.selectedCheckboxes.map((descriptorFlavour) => (
        <Badge
          key={descriptorFlavour.id}
          onClick={() =>
            !completed && descriptorFlavours.deselectCheckbox(descriptorFlavour)
          }
          icon='cross'
          className={classNames(
            'badge',
            'badge__flavour',
            `badge__flavour--${descriptorFlavour.kind}`,
            completed ? 'badge--disabled' : 'badge--clickable'
          )}
        >
          {descriptorFlavour.name}
        </Badge>
      ))}
    </div>
  );

  // Sent empty form fields in case of partial save
  const renderEmptyFormFields = (name) => {
    return <input type='hidden' name={name} value={''} />;
  };

  // necessary to send the entered data from the overlay
  const renderHiddenFormFields = () => {
    const flavourBoxes = descriptorFlavours.selectedCheckboxes;
    const flavourName = `samples[${sample.id}][descriptor_flavour_ids][]`;

    const alternativeBoxes = alternatives.selectedCheckboxes;
    const alternativeName = `samples[${sample.id}][alternative_ids][]`;

    const defectBoxes = defects.selectedCheckboxes;
    const defectName = `samples[${sample.id}][defect_ids][]`;

    return (
      <>
        {/* flavours */}
        {flavourBoxes.map(({ id }) => {
          return <input key={id} type='hidden' name={flavourName} value={id} />;
        })}
        {}
        {flavourBoxes.length < 1 && renderEmptyFormFields(flavourName)}

        {alternativeBoxes.map(({ id }) => {
          return <input key={id} type='hidden' name={alternativeName} value={id} />;
        })}
        {alternativeBoxes.length < 1 && renderEmptyFormFields(alternativeName)}

        {/* defects */}
        {defectBoxes.map(({ id }) => {
          return <input key={id} type='hidden' name={defectName} value={id} />;
        })}
        {defectBoxes.length < 1 && renderEmptyFormFields(defectName)}

        {/* overalls */}
        {descriptorOveralls.selectedRadio && (
          <input
            type='hidden'
            name={`samples[${sample.id}][descriptor_overall_id]`}
            value={`${descriptorOveralls.selectedRadio.id}`}
          />
        )}

        {/* bodies */}
        {descriptorBodies.selectedRadio && (
          <input
            type='hidden'
            name={`samples[${sample.id}][descriptor_body_id]`}
            value={`${descriptorBodies.selectedRadio.id}`}
          />
        )}

        {/* roasts */}
        {descriptorRoasts.selectedRadio && (
          <input
            type='hidden'
            name={`samples[${sample.id}][descriptor_roast_id]`}
            value={`${descriptorRoasts.selectedRadio.id}`}
          />
        )}

        {/* observation */}
        <input
          type='hidden'
          name={`samples[${sample.id}][observation]`}
          value={observation}
        />
      </>
    );
  };

  const fullIdentifierString = sample.parent_frontend_identifier
    ? `${sample.parent_frontend_identifier} || ${I18n.translate(
        'sensorial_analysis.sample'
      )}: ${sample.frontend_identifier}`
    : sample.frontend_identifier;

  const identifierWithSource = () => {
    const identifierInfo = sample.identifier_info || '';
    const parsedIdentifierInfo = identifierInfo.length > 0 ? `(${identifierInfo})` : null;
    return [fullIdentifierString, parsedIdentifierInfo].filter(Boolean).join(' ');
  };

  const cardNumber = () => {
    if (cuppingSessionEnabled) {
      return <span>{cuppingTableNumber} | &nbsp;</span>;
    }

    return (
      <span className='cupping-card__number'>
        <span>{count}</span> | {lotsCount}
      </span>
    );
  };

  return (
    <>
      <div className='card cupping-card'>
        <header className='card__header cupping-card__header'>
          <h2 className='cupping-card__headline'>
            {cardNumber()}
            <span className='cupping-card__id identifier'>{identifierWithSource()}</span>
          </h2>
          <ScoreInput
            sampleId={sample.id}
            completed={completed}
            small={true}
            value={score}
            cuppingSessionEnabled={cuppingSessionEnabled}
            onChange={setScore}
            onFetch={setRejectGrade}
            quality={quality}
            setQuality={setQuality}
            markAsReject={markAsReject}
          />
        </header>

        <div className='card__body'>
          <div className='cupping-card__body'>
            <div className='cupping-card__flavors' data-cy='flavours'>
              <div className='l-item-stack'>
                <div className='l-item-stack-horizontal'>
                  <CuppingSessionOverlay
                    lotLocalIdentifier={sample.local_identifier}
                    cuppingCardIndex={count}
                    widgetAmount={lotsCount}
                    overlayIndex={++overlayIndex}
                    completed={completed}
                    buttonTitle={I18n.translate('sensorial_analysis.add_flavours')}
                    selectedTitle={I18n.translate('sensorial_analysis.add_flavours')}
                    title={I18n.translate('sensorial_analysis.select_flavours')}
                    subtitle={fullIdentifierString}
                    showRequired={
                      descriptorFlavours.selectedCheckboxes.length < 3 &&
                      (conclusion.accepted || conclusion.rejected)
                    }
                  >
                    <FlavourInput
                      descriptorFlavours={descriptorFlavours}
                      sampleId={sample.id}
                      threeColumns={true}
                    />
                  </CuppingSessionOverlay>
                  <div className='cupping-card__flavors-info'>
                    <Icon icon='info' className='icon--small' color={COLORS.BLUE} />
                    <span>{I18n.translate('sensorial_analysis.minimum_flavours')}</span>
                  </div>
                </div>
                {renderSelectedFlavours}
              </div>
            </div>
            <div className='cupping-card__descriptors'>
              <div
                className='l-auto-fill-grid'
                style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(11em, 1fr))' }}
              >
                <div className='cupping-card__descriptor-group' data-cy='overall'>
                  <span className='cupping-card__descriptor-label'>
                    {I18n.translate('sensorial_analysis.overall')}:
                  </span>
                  <CuppingSessionOverlay
                    lotLocalIdentifier={sample.local_identifier}
                    cuppingCardIndex={count}
                    widgetAmount={lotsCount}
                    completed={completed}
                    buttonTitle={I18n.translate('sensorial_analysis.descriptors')}
                    showRequired={
                      !completed && (conclusion.accepted || conclusion.rejected)
                    }
                    filled={descriptorOveralls.selectedRadio}
                    overlayIndex={++overlayIndex}
                    title={I18n.translate('sensorial_analysis.select_overall')}
                    subtitle={fullIdentifierString}
                    selectedBadgeKind='overall'
                    selectedTitle={
                      descriptorOveralls.selectedRadio &&
                      descriptorOveralls.selectedRadio.name
                    }
                  >
                    <OverallInput
                      descriptorOveralls={descriptorOveralls}
                      sampleId={sample.id}
                      fourColumns={true}
                      useInOverlay={true}
                    />
                  </CuppingSessionOverlay>
                </div>
                <div className='cupping-card__descriptor-group' data-cy='alternatives'>
                  <span className='cupping-card__descriptor-label'>
                    {I18n.translate('sensorial_analysis.alternatives')}:
                  </span>
                  <CuppingSessionOverlay
                    lotLocalIdentifier={sample.local_identifier}
                    cuppingCardIndex={count}
                    widgetAmount={lotsCount}
                    overlayIndex={++overlayIndex}
                    completed={completed}
                    buttonTitle={I18n.translate('sensorial_analysis.descriptors')}
                    filled={!!alternatives.selectedCheckboxes.length}
                    title={I18n.translate('sensorial_analysis.select_alternatives')}
                    subtitle={fullIdentifierString}
                  >
                    <AlternativeInput
                      alternatives={alternatives}
                      sampleId={sample.id}
                      threeColumns={true}
                      useInOverlay={true}
                    />
                  </CuppingSessionOverlay>
                </div>
                <div className='cupping-card__descriptor-group' data-cy='roast'>
                  <span className='cupping-card__descriptor-label'>
                    {I18n.translate('sensorial_analysis.roast')}:
                  </span>
                  <CuppingSessionOverlay
                    lotLocalIdentifier={sample.local_identifier}
                    cuppingCardIndex={count}
                    widgetAmount={lotsCount}
                    completed={completed}
                    buttonTitle={I18n.translate('sensorial_analysis.descriptors')}
                    filled={descriptorRoasts.selectedRadio}
                    overlayIndex={++overlayIndex}
                    title={I18n.translate('sensorial_analysis.select_roast')}
                    subtitle={fullIdentifierString}
                    selectedBadgeKind='overall'
                    selectedTitle={
                      descriptorRoasts.selectedRadio &&
                      descriptorRoasts.selectedRadio.name
                    }
                  >
                    <RoastInput
                      descriptorRoasts={descriptorRoasts}
                      sampleId={sample.id}
                      fourColumns={true}
                      useInOverlay={true}
                    />
                  </CuppingSessionOverlay>
                </div>
                <div className='cupping-card__descriptor-group' data-cy='body'>
                  <span className='cupping-card__descriptor-label'>
                    {I18n.translate('sensorial_analysis.body')}:
                  </span>
                  <CuppingSessionOverlay
                    lotLocalIdentifier={sample.local_identifier}
                    cuppingCardIndex={count}
                    widgetAmount={lotsCount}
                    completed={completed}
                    buttonTitle={I18n.translate('sensorial_analysis.descriptors')}
                    showRequired={
                      !completed && (conclusion.accepted || conclusion.rejected)
                    }
                    filled={descriptorBodies.selectedRadio}
                    overlayIndex={++overlayIndex}
                    title={I18n.translate('sensorial_analysis.select_bodies')}
                    subtitle={fullIdentifierString}
                    selectedBadgeKind={
                      descriptorBodies.selectedRadio &&
                      `body--${descriptorBodies.selectedRadio.kind}`
                    }
                    selectedTitle={
                      descriptorBodies.selectedRadio &&
                      descriptorBodies.selectedRadio.name.charAt(0) +
                        ': ' +
                        descriptorBodies.selectedRadio.name.split(': ')[1]
                    }
                  >
                    <div className='l-auto-fill-flex'>
                      <BodyInput
                        sampleId={sample.id}
                        useInOverlay={true}
                        descriptorBodies={descriptorBodies}
                      />
                    </div>
                  </CuppingSessionOverlay>
                </div>
                <div className='cupping-card__descriptor-group' data-cy='defects'>
                  <span className='cupping-card__descriptor-label'>
                    {I18n.translate('sensorial_analysis.defects')}:
                  </span>
                  <CuppingSessionOverlay
                    lotLocalIdentifier={sample.local_identifier}
                    cuppingCardIndex={count}
                    widgetAmount={lotsCount}
                    overlayIndex={++overlayIndex}
                    completed={completed}
                    buttonTitle={I18n.translate('sensorial_analysis.descriptors')}
                    filled={!!defects.selectedCheckboxes.length}
                    title={I18n.translate('sensorial_analysis.select_defects')}
                    subtitle={fullIdentifierString}
                  >
                    <DefectInput
                      defects={defects}
                      sampleId={sample.id}
                      threeColumns={true}
                      useInOverlay={true}
                    />
                  </CuppingSessionOverlay>
                </div>
                <div className='cupping-card__descriptor-group' data-cy='observations'>
                  <span className='cupping-card__descriptor-label'>
                    {I18n.translate('sensorial_analysis.observations')}:
                  </span>
                  <CuppingSessionOverlay
                    lotLocalIdentifier={sample.local_identifier}
                    cuppingCardIndex={count}
                    widgetAmount={lotsCount}
                    overlayIndex={++overlayIndex}
                    completed={completed}
                    buttonTitle={I18n.translate('sensorial_analysis.add')}
                    filled={!!observation.length}
                    title={I18n.translate('sensorial_analysis.add_observations')}
                    subtitle={fullIdentifierString}
                  >
                    <div className='base-padding'>
                      <ObservationInput
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                        sampleId={sample.id}
                      />
                    </div>
                  </CuppingSessionOverlay>
                </div>
              </div>
            </div>
            <footer className='cupping-card__footer'>
              <ConclusionInput
                sampleId={sample.id}
                conclusion={conclusion}
                completed={completed}
                withReason={defectsSelected || badScore}
                cuppingSessionEnabled={cuppingSessionEnabled}
              />
            </footer>
          </div>
        </div>
      </div>
      {renderHiddenFormFields()}
    </>
  );
};

export default observer(SensorialAnalysis);
