import FinalizeService, { FinalizableResponse } from 'exports/services/FinalizeService';
import { deserialise } from 'kitsu-core';
import { customFetch, methodInterface } from 'utils/fetch';
import Export from 'exports/models/Export';

class ShippingAdviceFinalizeService<T extends object> extends FinalizeService<T> {
  constructor(
    export_: Export,
    createRoute: (id: number) => string,
    updateRoute: (id: number) => string,
    finalizeRoute: (id: number) => string,
    private sendOutRoute: (id: number) => string // extra argument
  ) {
    super(export_, createRoute, updateRoute, finalizeRoute);
  }

  // New async method
  public async sendOut(id: number, payload: T): Promise<T> {
    const fetchConfig = {
      path: this.sendOutRoute(id),
      method: 'POST' as keyof typeof methodInterface,
    };

    const response: FinalizableResponse = await customFetch(
      fetchConfig.path,
      payload, // Now constrained to be an object
      fetchConfig.method
    );

    this.updateExport(response.export);

    return deserialise(response.finalizableResource).data as T;
  }
}

export default ShippingAdviceFinalizeService;
