import Grid from 'components/elements/Grid';
import InputGroup from 'components/groups/InputGroup';
import COLORS from 'constants/colors';
import FormFieldSet from 'exports/components/FormFieldSet';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import type ShippingAdviceModel from '../../models/ShippingAdvice';
import type ShippingAdviceType from '../../types/shippingAdvice';
import FormButton from '../FormButton';

import AccordionFormBody from '../AccordionFormBody';

export interface ShippingAdviceProps {
  shippingAdvice: ShippingAdviceModel;
  exportIncoterm: String;
  packingListSealNumber?: string;
}

const ShippingAdviceWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY};
  padding: 0.625rem 0;
`;

const ShippingAdvice = ({
  shippingAdvice,
  exportIncoterm,
  packingListSealNumber,
}: ShippingAdviceProps) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: shippingAdvice,
  });

  const [eta, setEta] = useState(shippingAdvice.eta);
  const [etd, setEtd] = useState(shippingAdvice.etd);
  const [containerNumber, setContainerNumber] = useState(shippingAdvice.container_number);

  const [canBeExported, setCanBeExported] = useState(
    shippingAdvice.can_be_exported === undefined ? true : shippingAdvice.can_be_exported
  );

  const onSaveClick = handleSubmit(async (formData: ShippingAdviceType) => {
    await shippingAdvice.save(formData);

    reset(formData);
  });

  const handleOnFinalizeClick = handleSubmit(async () => {
    await shippingAdvice.finalize();
  });

  const onExportClick = handleSubmit(async (formData: ShippingAdviceType) => {
    await shippingAdvice.sendOut(formData);
    setCanBeExported(
      shippingAdvice.can_be_exported === undefined ? true : shippingAdvice.can_be_exported
    );
    reset(formData);
  });

  const attributesFilled = Boolean(
    eta && etd && containerNumber && packingListSealNumber
  );
  const renderExportButton = () => {
    if (shippingAdvice.finalized) return null;

    return (
      <FormButton>
        <button
          disabled={!attributesFilled || !canBeExported || isSubmitting}
          type='button'
          onClick={onExportClick}
          className='button button--green'
        >
          {I18n.translate('buttons.export')}
        </button>
      </FormButton>
    );
  };

  return (
    <ShippingAdviceWrapper>
      <AccordionFormBody
        isFinalizable={shippingAdvice.isFinalizable}
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        finalized={shippingAdvice.finalized}
        onSubmitClick={onSaveClick}
        onFinalizeClick={handleOnFinalizeClick}
        downloadUrl={
          exportIncoterm == 'CIF' || exportIncoterm == 'FOB'
            ? shippingAdvice.downloadUrl
            : ''
        }
        extraSubmitButton={renderExportButton()}
      >
        <FormFieldSet disabled={shippingAdvice.finalized}>
          <Grid grid={16}>
            <InputGroup
              id='date_of_arrival'
              name='date_of_arrival'
              ref={register}
              type='date'
              label={I18n.translate('exports.form.shipping_advice.date_of_arrival_label')}
              placeholder={I18n.translate('exports.form.shipping_advice.etd_placeholder')}
            />
            <InputGroup
              id='etd'
              name='etd'
              ref={register}
              value={etd}
              onChange={(e) => setEtd(e.target.value)}
              type='date'
              label={I18n.translate('exports.form.shipping_advice.etd_label')}
              placeholder={I18n.translate('exports.form.shipping_advice.etd_placeholder')}
              disabled={!canBeExported}
            />

            <InputGroup
              id='eta'
              name='eta'
              ref={register}
              value={eta}
              onChange={(e) => setEta(e.target.value)}
              type='date'
              label={I18n.translate('exports.form.shipping_advice.eta_label')}
              placeholder={I18n.translate('exports.form.shipping_advice.eta_placeholder')}
            />

            <InputGroup
              id='container_number'
              name='container_number'
              value={containerNumber}
              onChange={(e) => setContainerNumber(e.target.value)}
              ref={register}
              label={I18n.translate(
                'exports.form.shipping_advice.container_number_label'
              )}
              placeholder={I18n.translate(
                'exports.form.shipping_advice.container_number_placeholder'
              )}
              disabled={!canBeExported}
            />

            <InputGroup
              id='seal_number'
              name='seal_number'
              label={I18n.translate('exports.form.packing_list.seal_number_label')}
              disabled={true}
              value={packingListSealNumber}
            />
          </Grid>
        </FormFieldSet>
      </AccordionFormBody>
    </ShippingAdviceWrapper>
  );
};

export default observer(ShippingAdvice);
