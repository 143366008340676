import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useStores from 'utils/useStores';

import BaseGroupComponent from 'components/groups/BaseGroup';
import Checkbox from 'shared/Checkbox';

import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import Input from 'components/elements/Input';
import { VARIANT } from 'constants/inputTypes';

import type AvailableCoffeItem from 'availableCoffee/models/AvailableCoffeItem';
import placeholder from 'utils/placeholder';
import InputColumn from './InputColumn';
import ItemColumn from './ItemColumn';

export const VarietyCol = styled.td`
  min-width: 12em;
  max-width: 12em;
`;

export const WeightCol = styled.td`
  min-width: 12em;
  max-width: 12em;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  input[type='text'] {
    width: 5em;
  }
`;

interface CoffeeItemRowProps {
  item: AvailableCoffeItem;
}

const CoffeeItemRow = ({ item }: CoffeeItemRowProps) => {
  const { availableCoffeeStore } = useStores();
  const [realSize, setLotSize] = React.useState(0);
  const [initialReserved] = useState(item.reserved);

  useEffect(() => {
    setLotSize(item.attributes.available_weight);
  }, [item.attributes.available_weight]);

  const idSubtitle = () => {
    const { warehouse, complies_eudr } = item.attributes;
    const eudrLabel = complies_eudr ? 'EUDR' : null;

    return [warehouse, eudrLabel].filter(Boolean).join(' | ');
  };

  return (
    <tr>
      <ItemColumn className='align-left'>
        <TextWithSub
          text={item.attributes.local_identifier}
          textClass='item--id'
          sub={idSubtitle()}
        />
      </ItemColumn>

      <td className='align-center'>
        <TextWithSub
          text={placeholder(item.attributes.quality)}
          sub={placeholder(item.attributes.grade)}
        />
      </td>

      <td>
        <TextWithSub
          text={placeholder(item.attributes.process_type)}
          sub={placeholder(item.attributes.certification)}
        />
      </td>

      <td className='align-center'>
        <TextWithSub
          text={item.attributes.purchase_factor}
          sub={placeholder(item.attributes.purchase_type)}
        />
      </td>

      <td className='align-center'>
        <TextWithSub
          text={item.attributes.secondary_factor}
          sub={placeholder(item.attributes.secondary_factor_info)}
        />
      </td>

      <WeightCol className='align-right'>
        <TextWithSub
          text={`${item.attributes.available_weight} kg / ${item.attributes.total_weight} kg`}
          sub={item.attributes.bag_info}
        />
      </WeightCol>

      {availableCoffeeStore.withTable && (
        <td className='align-center'>
          {initialReserved && (
            <Checkbox
              checked={item.selected}
              disabled={true}
              onChange={() => {
                /* do nothing */
                /* checkox is not working without this */
              }}
            />
          )}
        </td>
      )}

      <InputColumn className='align-center'>
        <InputWrapper>
          <BaseGroupComponent append='kg' inline={true}>
            <Input
              variant={VARIANT.DECIMAL}
              condensed
              value={item.quantity}
              readOnly={!item.selected || !availableCoffeeStore.withTable}
              step={0.01}
              min={0}
              max={item.attributes.available_weight}
              validator={(e) =>
                parseFloat(e.currentTarget.value) <= item.attributes.available_weight
              }
              onChange={(e) => item.setQuantity(e.currentTarget.value)}
            />
          </BaseGroupComponent>
          {availableCoffeeStore.withTable && (
            <Checkbox
              dataCy='check_available_coffee'
              checked={item.selected}
              disabled={!realSize}
              onChange={() => availableCoffeeStore.toggleItemSelection(item)}
            />
          )}
        </InputWrapper>
      </InputColumn>
    </tr>
  );
};

export default observer(CoffeeItemRow);
