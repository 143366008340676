import React, { useState } from 'react';
import InputGroupComponent from 'components/groups/InputGroup';
import ToolTip from 'shared/ToolTip/components/ToolTip';

const SpotCoffee = ({ ico, editMode }) => {
  const [currentUnits, setCurrentUnits] = useState(ico.desired_units || 0);
  const [currentSpotIcoCost, setCurrentSpotIcoCost] = useState(ico.spot_ico_cost || 0);
  return (
    <tr key={ico.id}>
      <th
        style={{
          position: 'absolute',
          boxShadow: 'unset',
        }}
      >
        <ToolTip
          title={`Additional Information`}
          icon='info'
          style={{
            marginTop: '50%',
            marginLeft: '50%',
          }}
          infos={[
            {
              label: I18n.translate('attributes.origin'),
              value: ico.origin_country,
            },
            {
              label: I18n.translate('attributes.mark'),
              value: ico.mark,
            },
            {
              label: I18n.translate('attributes.grade'),
              value: ico.quality,
            },
            {
              label: I18n.translate('attributes.certificate'),
              value: ico.certificate || '-',
            },
            {
              label: I18n.translate('opportunities.form.capacity_and_packaging'),
              value: ico.packagingType,
            },
          ]}
        />
      </th>
      <th>
        <InputGroupComponent
          key={ico.ico_with_secondary_number || ico.identifier?.name}
          name={''}
          className='align-center'
          readOnly
          value={ico.ico_with_secondary_number || ico.identifier?.name}
        />
        <input
          key={ico.id}
          type='hidden'
          name={`contract[spot_contract_icos_attributes][][ico_id]`}
          value={ico.id}
        />
      </th>
      <th style={{ paddingLeft: '0.4375em' }}>
        <InputGroupComponent
          key={ico.id}
          name={`contract[spot_contract_icos_attributes][][spot_ico_cost]`}
          value={currentSpotIcoCost}
          readOnly={!editMode}
          type='number'
          step='0.0001'
          min='0.0001'
          onChange={(e) => setCurrentSpotIcoCost(e.target.value)}
        />
      </th>
      <th>
        <InputGroupComponent
          key={ico.id}
          name={`contract[spot_contract_icos_attributes][][units]`}
          type='number'
          min={1}
          value={currentUnits}
          readOnly={!editMode}
          max={ico.available_units || currentUnits || 0}
          append={ico.available_units ? `/${ico.available_units}` : ''}
          onChange={(e) => setCurrentUnits(e.target.value)}
        />
      </th>
    </tr>
  );
};

export default SpotCoffee;
