import React, { useEffect, useMemo, useState } from 'react';
import Routes from 'routes';
import CoffeeDetails from './CoffeeDetails';
import { ContractType } from './segments/ContractType';
import { CompaniesInvolved } from './segments/CompaniesInvolved';
import { Price } from './segments/Price';
import { Shipment } from './segments/Shipment';
import { AdditionalInformation } from './segments/AdditionalInformation';
import { ContractClosure } from './segments/ContractClosure';
import { IField } from 'components/shared/form/IField';
import { fetchContractPrice } from 'contracts/utils/fetchContractPrice';
import SpotCoffeeDetails from './SpotCoffeeDetails';
import { AvailableCoffeeType } from 'opportunities/components/AvailableCoffee';
import { customFetch } from 'utils/fetch';
import { SelectOption } from 'types/model/selectOption';
import { getField, hasSegment } from 'contracts/utils/formHelpers';
import { PriceProvider, usePrice } from 'contracts/utils/priceContext';
import { fromMonthInputFormat } from 'contracts/contractUtils';

type CountrySelectOption = SelectOption & {
  country_id?: string;
};
interface ContractFormProps {
  fields: IField[];
  coffeeDetails: {
    availableCoffee: Array<AvailableCoffeeType>;
    spotIcos: AvailableCoffeeType[];
    opportunityEstimatedKg: string;
  };
  currency?: string;
  unitOfMeasurement?: string;
  shipperCountryId?: number;
  contractType?: string;
  editMode?: boolean;
}

const ContractForm = ({
  coffeeDetails,
  fields,
  shipperCountryId,
  contractType,
  editMode = false,
  currency,
  unitOfMeasurement,
}: ContractFormProps) => {
  const prices = usePrice(fields);
  const { importMarkup, fixedPriceType, fobPrice } = prices;
  const isFixedPriceType = useMemo(() => fixedPriceType?.value == 'fixed', []);
  const [incotermField] = useState(getField(fields, 'incoterm'));
  const [incoterm, setIncoterm] = useState(incotermField?.value || '');
  const [contractTypeField] = useState(getField(fields, 'contract_type'));
  const [monthlyCarryField] = useState(getField(fields, 'monthly_carry'));

  const showMarkups = useMemo(
    () =>
      ['FwdContract', 'InternalContract', 'ThirdPartyContract'].includes(
        contractType ?? ''
      ),
    [incoterm]
  );

  const getFloatFieldValue = (
    value: string | number | undefined,
    defaultValue: string
  ): string => {
    const numberValue = typeof value === 'string' ? value : value?.toString();
    return numberValue || defaultValue;
  };

  const getIntegerFieldValue = (
    value: string | number | undefined,
    defaultValue: string
  ): string => {
    const numberValue = typeof value === 'string' ? value : value?.toString();
    return numberValue || defaultValue;
  };

  const tmpMonthlyCarry = getFloatFieldValue(monthlyCarryField?.value, '1.5');
  const [monthlyCarry, setMonthlyCarry] = useState(tmpMonthlyCarry);

  const defaultMaxCarry = contractType == 'FwdContract' ? '180' : '6';
  const [maxCarryField] = useState(getField(fields, 'max_carry'));
  const tmpMaxCarry = getIntegerFieldValue(maxCarryField?.value, defaultMaxCarry);
  const [maxCarry, setMaxCarry] = useState(tmpMaxCarry);

  const [freeDaysField] = useState(getField(fields, 'free_days'));
  const defaultFreeDays = contractType == 'SpotContract' ? '90' : '10';

  const tmpFreeDays = getIntegerFieldValue(freeDaysField?.value, defaultFreeDays);
  const [freeDays, setFreeDays] = useState(tmpFreeDays);

  // Companies Involved
  const [customerField] = useState(getField(fields, 'customer_id'));
  const [customerReferenceField] = useState(getField(fields, 'customer_reference'));
  const caravelaSalesStaffField = fields.find(
    (field) => field.name == 'caravela_sales_staff'
  );
  const [shipperIdField] = useState(getField(fields, 'shipper_id'));
  const [shipperId, setShipperId] = useState(getField(fields, 'shipper_id')?.value || '');

  const [originCountryId, setOriginCountryId] = useState(shipperCountryId);

  useEffect((): void => {
    const shipper = shipperIdField?.options?.find((option) => option.id == shipperId);

    // @ts-ignore: Wrongly typed Options
    shipper && setOriginCountryId(shipper.country_id);
  }, [shipperId]);

  const [thirdPartyShipperIdField] = useState(getField(fields, 'third_party_shipper_id'));
  const [thirdPartyShipperId, setThirdPartyShipperId] = useState(
    thirdPartyShipperIdField?.value || ''
  );
  const [intermediaryIdField] = useState(getField(fields, 'intermediary_id'));
  const [sellerIdField] = useState(getField(fields, 'seller_id'));

  // Price
  const priceTypeField = getField(fields, 'price_type');
  const [futurePriceFixingDateField] = useState(
    getField(fields, 'future_price_fixing_date')
  );
  const [fixationRightsField] = useState(getField(fields, 'fixation_rights'));

  // Shipment
  const [destinationWarehouseIdField] = useState(
    getField(fields, 'destination_warehouse_id')
  );
  const [destinationWarehouseId, setDestinationWarehouseId] = useState(
    destinationWarehouseIdField?.value || ''
  );
  const [warehouseIdField] = useState(getField(fields, 'warehouse_id'));
  const [warehouseId, setWarehouseId] = useState(
    // @ts-ignore: Wrongly typed Options
    warehouseIdField?.value || ''
  );
  const [shipperPortIdField] = useState(getField(fields, 'shipper_port_id'));
  const [shipperPortId, setShipperPortId] = useState(shipperPortIdField?.value || '');
  const [destinationPortIdField] = useState(getField(fields, 'destination_port_id'));
  const [destinationPortId, setDestinationPortId] = useState(
    destinationPortIdField?.value || ''
  );

  const [shipmentDateField] = useState(getField(fields, 'shipment_date'));
  const [shipmentDate, setShipmentDate] = useState(
    (shipmentDateField?.value && fromMonthInputFormat(shipmentDateField.value)) ||
      new Date()
  );

  const [deliveryDateField] = useState(getField(fields, 'delivery_date'));
  const [deliveryDate, setDeliveryDate] = useState(
    (deliveryDateField?.value && fromMonthInputFormat(deliveryDateField.value)) || ''
  );

  // Additional Information
  const [contractQualityIdField] = useState(getField(fields, 'contract_quality_id'));
  const [contractQualityId, setContractQualityId] = useState(
    contractQualityIdField?.value || ''
  );
  const [preparationIdField] = useState(getField(fields, 'preparation_id'));
  const [preparationId, setPreparationId] = useState(preparationIdField?.value || '');
  const [approvalTermIdField] = useState(getField(fields, 'approval_term_id'));
  const [observationsField] = useState(getField(fields, 'observations'));
  const [observations, setObservations] = useState(observationsField?.value || '');

  const [contractClosureReasonIdField] = useState(
    getField(fields, 'contract_closure_reason_id')
  );
  const [contractClosureReasonId, setContractClosureReasonId] = useState(
    contractClosureReasonIdField?.value || ''
  );
  const [contractCancellationReasonIdField] = useState(
    getField(fields, 'contract_cancellation_reason_id')
  );
  const [contractCancellationReasonId, setContractCancellationReasonId] = useState(
    contractCancellationReasonIdField?.value || ''
  );

  const [closeContractChecked, setCloseContractChecked] = useState(
    !!contractClosureReasonId
  );

  useEffect(() => {
    async function fetchImportAndSetMarkup(): Promise<void> {
      const {
        data: { import_markup },
      } = await customFetch(
        Routes.import_markup_api_v1_contracts_path({
          origin_country_id: originCountryId,
          unit_of_measurement: unitOfMeasurement,
          quality_id: qualityId,
          incoterm,
          currency,
        }),
        undefined,
        'GET'
      );

      importMarkup?.set(import_markup ?? '0.0');
    }

    showMarkups &&
      importMarkup?.value &&
      parseInt(importMarkup?.value) === 0 &&
      unitOfMeasurement &&
      currency &&
      incoterm &&
      originCountryId &&
      fetchImportAndSetMarkup();
  }, [showMarkups, incoterm, originCountryId]);

  // FWD or Thirdparty
  // only DLD and EXW have a DeliveryDate and DestinationWarehouse field of those contracts with an incoterm
  const deliveryFieldsHidden = useMemo(
    () => incotermField && !['DLD', 'EXW', 'FCA', 'DDP'].includes(incoterm),
    [incoterm]
  );

  const filterOptionsByShipperCountry = (
    initalOptions: CountrySelectOption[] | undefined
  ): CountrySelectOption[] => {
    if (initalOptions) {
      let countryId = shipperCountryId;
      if (shipperIdField && shipperIdField.options) {
        countryId =
          // @ts-ignore: Wrongly typed Options
          shipperIdField.options.find((option) => option.id == shipperId)?.country_id;
      } else if (thirdPartyShipperIdField && thirdPartyShipperIdField.options) {
        countryId = thirdPartyShipperIdField?.options.find(
          (option) => option.id == thirdPartyShipperId
          // @ts-ignore: Wrongly typed Options
        )?.country_id;
      }
      if (countryId) {
        return initalOptions.filter((option) => option.country_id == countryId);
      }
    }

    return initalOptions || [];
  };
  // Disable shipmentports outside of the country of shipper office
  const filteredShipperPorts = filterOptionsByShipperCountry(shipperPortIdField?.options);
  const filteredDestinationWarehouseOptions = destinationWarehouseIdField?.options;
  const filteredWarehouseOptions = warehouseIdField?.options;
  const packagingTypeIdField = getField(fields, 'packaging_type_id');
  const filteredPackagingTypeOptions = filterOptionsByShipperCountry(
    packagingTypeIdField?.options
  );
  const markIdField = getField(fields, 'mark_id');
  const filteredMarkOptions = filterOptionsByShipperCountry(markIdField?.options);

  const [qualityIdField] = useState(getField(fields, 'quality_id'));
  const [qualityId, setQualityId] = useState(qualityIdField?.value || '');
  const [markId, setMarkId] = useState(markIdField?.value || '');

  useEffect(() => {
    async function fetchAndSetContractPrice(): Promise<void> {
      const requiredParams = markId && qualityId && currency && unitOfMeasurement;
      const pristineFobPrice =
        fobPrice?.value === '' || (fobPrice && parseFloat(fobPrice.value) == 0);

      if (requiredParams && pristineFobPrice) {
        const { contract_price } = await fetchContractPrice({
          markId,
          qualityId,
          currency,
          unitOfMeasurement,
          customerId: customerField?.value,
        });

        if (isFixedPriceType) {
          const parsedFixedPrice = parseFloat(contract_price.fixed_price);

          if (parsedFixedPrice > 0) {
            fobPrice?.set(contract_price.fixed_price);
          }
        } else {
          const parseddifferentialPricePrice = parseFloat(contract_price.differential);

          if (parseddifferentialPricePrice > 0) {
            fobPrice?.set(contract_price.differential);
          }
        }
      }
    }

    if (markId && qualityId && currency && unitOfMeasurement) {
      fetchAndSetContractPrice();
    }
  }, [markId, qualityId]);

  // Disable delivery date and shipment date validations when cancel contract
  const [cancelContractChecked, setCancelContractChecked] = useState(
    !!contractCancellationReasonId
  );
  return (
    <>
      {hasSegment(fields, 'contract_type') && (
        <ContractType
          contractTypeField={contractTypeField}
          incotermField={incotermField}
          incoterm={incoterm}
          setIncoterm={setIncoterm}
          monthlyCarryField={monthlyCarryField}
          monthlyCarry={monthlyCarry}
          setMonthlyCarry={setMonthlyCarry}
          maxCarryField={maxCarryField}
          maxCarry={maxCarry}
          setMaxCarry={setMaxCarry}
          freeDaysField={freeDaysField}
          freeDays={freeDays}
          setFreeDays={setFreeDays}
        />
      )}

      {hasSegment(fields, 'companies_involved') && (
        <CompaniesInvolved
          customerField={customerField}
          customerReferenceField={customerReferenceField}
          caravelaSalesStaffField={caravelaSalesStaffField}
          shipperIdField={shipperIdField}
          shipperId={shipperId}
          setShipperId={setShipperId}
          thirdPartyShipperIdField={thirdPartyShipperIdField}
          thirdPartyShipperId={thirdPartyShipperId}
          setThirdPartyShipperId={setThirdPartyShipperId}
          intermediaryIdField={intermediaryIdField}
          sellerIdField={sellerIdField}
        />
      )}

      {contractType == 'SpotContract' ? (
        <SpotCoffeeDetails
          fields={fields}
          spotIcos={coffeeDetails.spotIcos}
          availableCoffee={coffeeDetails.availableCoffee}
          opportunityEstimatedKg={coffeeDetails.opportunityEstimatedKg}
          editMode={editMode}
        />
      ) : (
        <CoffeeDetails
          packagingTypeOptions={filteredPackagingTypeOptions}
          markOptions={filteredMarkOptions}
          fields={fields}
          preparationIdField={preparationIdField}
          preparationId={preparationId}
          setPreparationId={setPreparationId}
          qualityIdField={qualityIdField}
          qualityId={qualityId}
          onQualityChange={setQualityId}
          markIdField={markIdField}
          markId={markId}
          onMarkChange={setMarkId}
          opportunityEstimatedKg={coffeeDetails.opportunityEstimatedKg}
        />
      )}

      {hasSegment(fields, 'shipment') && (
        <Shipment
          destinationWarehouseIdField={destinationWarehouseIdField}
          destinationWarehouseId={destinationWarehouseId}
          setDestinationWarehouseId={setDestinationWarehouseId}
          destinationWarehouseOptions={filteredDestinationWarehouseOptions}
          warehouseIdField={warehouseIdField}
          warehouseId={warehouseId}
          setWarehouseId={setWarehouseId}
          warehouseOptions={filteredWarehouseOptions}
          shipperPortIdField={shipperPortIdField}
          shipperPortId={shipperPortId}
          shipperPortOptions={filteredShipperPorts}
          setShipperPortId={setShipperPortId}
          destinationPortIdField={destinationPortIdField}
          destinationPortId={destinationPortId}
          setDestinationPortId={setDestinationPortId}
          shipmentDateField={shipmentDateField}
          shipmentDate={shipmentDate}
          setShipmentDate={setShipmentDate}
          destinationWarehouseHidden={deliveryFieldsHidden}
          warehouseHidden={deliveryFieldsHidden}
          deliveryDateHidden={deliveryFieldsHidden}
          deliveryDateField={deliveryDateField}
          deliveryDate={deliveryDate}
          setDeliveryDate={setDeliveryDate}
          cancelContractChecked={cancelContractChecked}
          closeContractChecked={closeContractChecked}
        />
      )}

      {hasSegment(fields, 'price') && (
        <PriceProvider value={prices}>
          <Price
            showMarkups={showMarkups}
            priceTypeField={priceTypeField}
            futurePriceFixingDateField={futurePriceFixingDateField}
            fixationRightsField={fixationRightsField}
            shipmentDate={shipmentDate}
          />
        </PriceProvider>
      )}

      {hasSegment(fields, 'additional_information') && (
        <AdditionalInformation
          contractQualityIdField={contractQualityIdField}
          contractQualityId={contractQualityId}
          setContractQualityId={setContractQualityId}
          approvalTermIdField={approvalTermIdField}
          observationsField={observationsField}
          observations={observations}
          setObservations={setObservations}
        />
      )}

      {hasSegment(fields, 'contract_closure') && (
        <ContractClosure
          contractClosureReasonIdField={contractClosureReasonIdField}
          contractClosureReasonId={contractClosureReasonId}
          setContractClosureReasonId={setContractClosureReasonId}
          contractCancellationReasonIdField={contractCancellationReasonIdField}
          contractCancellationReasonId={contractCancellationReasonId}
          setContractCancellationReasonId={setContractCancellationReasonId}
          cancelContractChecked={cancelContractChecked}
          setCancelContractChecked={setCancelContractChecked}
          closeContractChecked={closeContractChecked}
          setCloseContractChecked={setCloseContractChecked}
        />
      )}
    </>
  );
};

export default ContractForm;
