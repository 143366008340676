import { Provider } from 'mobx-react';
import React from 'react';

import { equalityFilter, likeFilter, booleanFilterNotEqual } from 'utils/filter';
import FilterContextProvider from 'utils/filterContext';

import AvailableCoffeeStore from 'availableCoffee/stores/AvailableCoffeeStore';
import { AvailableCoffeItem } from 'types/model/lot';
import AssignCoffee from 'availableCoffee/components/AssignCoffee';
import SelectOptionArray from 'types/model/selectOption';
import type AvailableMillingOutputType from 'availableCoffee/types/AvailableMillingOutput';
import { JsonApi } from 'types/utils/jsonApi';
import { ProductionOrderType } from 'types/model/productionOrder';

interface AvailableCoffeeContainerProps {
  productionOrderType: ProductionOrderType;
  reservedItems?: Array<{ local_identifier: string; quantity: number }>;
  selectedItems?: Array<{ local_identifier: string; quantity: number }>;
  lots?: JsonApi<AvailableCoffeItem>;
  lotSets?: JsonApi<AvailableCoffeItem>;
  millingOutputs?: JsonApi<AvailableMillingOutputType>;
  caracols: Array<unknown>;
  exportSize: number;
  wantedWeight: number;
  withTable?: boolean;
  productionOrderId?: number;
  warehouses: SelectOptionArray;
  qualities: SelectOptionArray;
  varieties: SelectOptionArray;
  processTypes: SelectOptionArray;
  icoIdentifier?: string;
  eudrValidationSkipped: boolean;
  eudrValidationSkippedEnabled: boolean;
}

const filterConfiguration = {
  local_identifier: {
    filter: likeFilter,
    value: undefined,
  },
  warehouse: {
    filter: equalityFilter,
    value: undefined,
  },
  quality: {
    filter: equalityFilter,
    value: undefined,
  },
  variety: {
    filter: equalityFilter,
    value: undefined,
  },
  process_type: {
    filter: equalityFilter,
    value: undefined,
  },
};

const AvailableCoffeeContainer = ({
  productionOrderType,
  reservedItems,
  selectedItems,
  icoIdentifier,
  millingOutputs,
  lots,
  lotSets,
  exportSize,
  wantedWeight,
  withTable,
  productionOrderId,
  warehouses,
  qualities,
  varieties,
  processTypes,
  eudrValidationSkipped,
  eudrValidationSkippedEnabled,
}: AvailableCoffeeContainerProps) => {
  const compliesEudrFilter = () => {
    if (!eudrValidationSkippedEnabled) return null;

    return {
      complies_eudr: {
        filter: booleanFilterNotEqual,
        value: eudrValidationSkipped ? 'true' : 'false',
      },
    };
  };

  const parsedFilters = { ...filterConfiguration, ...compliesEudrFilter() };
  return (
    <Provider
      availableCoffeeStore={
        new AvailableCoffeeStore(
          productionOrderType,
          lots,
          lotSets,
          millingOutputs,
          reservedItems,
          selectedItems,
          exportSize,
          wantedWeight,
          withTable,
          productionOrderId,
          warehouses,
          qualities,
          varieties,
          processTypes,
          icoIdentifier,
          eudrValidationSkipped,
          eudrValidationSkippedEnabled
        )
      }
    >
      <FilterContextProvider value={parsedFilters}>
        <AssignCoffee />
      </FilterContextProvider>
    </Provider>
  );
};

export default AvailableCoffeeContainer;
