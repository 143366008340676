import React from 'react';

import { LotSetTraceability } from '../types/traceability';
import Routes from 'routes';

import Icon from 'components/elements/Icon';
import SpaceBetween from 'components/elements/SpaceBetween';
import { TileList } from 'shared/Tile';

import {
  DateDetail,
  DisclosureDetail,
  IdentifierDetail,
  PrimaryDetail,
  SecondaryDetail,
  CenteredSecondaryDetail,
  IdentifierDetailInfo,
} from './Detail';

import { TraceabilityDetails } from './Details';
import SetPurchaseInformationOverlay from './SetPurchaseInformationOverlay';
import SelectOptionArray from 'types/model/selectOption';
import { customFetch } from 'utils/fetch';
import SpecialMarkSelect from './SpecialMarkSelect';
import TypeBadge from './TypeBadge';
import WarehouseInfo from './WarehouseInfo';
import { weightToString } from 'utils/weight';
import { SensorialAndOriginDetails } from './SensorialDetails';
import ActionStack from './ActionStack';
import LotSetRemoveButton from './LotSetRemoveButton';
export interface SetTraceabilityHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  onCollapsibleHandler: (event: React.MouseEvent) => any;
  collapsed: boolean;
  traceability: LotSetTraceability;
  specialMarks: SelectOptionArray;
}

const SetTraceabilityHeader = ({
  traceability,
  traceability: {
    id,
    type,
    created_at,
    grade,
    certificate,
    quality,
    current_weight,
    local_identifier,
    current_number_bags,
    sample,
    traveled,
    warehouse_name,
    special_mark_id,
    splitable,
    splitable_info,
    complies_eudr,
  },
  collapsed,
  onCollapsibleHandler,
  specialMarks,
  ...props
}: SetTraceabilityHeaderProps) => {
  const state = undefined;
  const [showOverlay, setOverlay] = React.useState(false);

  const overlayProps = {
    onPrimaryClick: () => setOverlay(!showOverlay),
  };

  const saveSpecialMark = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const path = Routes.api_v1_lot_set_path;

    customFetch(path(id), {
      special_mark_id: event.target.value,
    });
  };

  const handleOnRemoveClick = (_event: React.MouseEvent): any => {
    return customFetch(Routes.api_v1_lot_set_path(id), undefined, 'DELETE').then(() => {
      window.location.reload();
    });
  };

  return (
    <TileList {...props}>
      {showOverlay && (
        <SetPurchaseInformationOverlay {...overlayProps} traceability={traceability} />
      )}

      <TraceabilityDetails>
        <TypeBadge type={type}>{type}</TypeBadge>

        <DateDetail>{created_at}</DateDetail>

        <PrimaryDetail data-cy='traceability_quality' align='center'>
          {quality}
        </PrimaryDetail>

        <PrimaryDetail data-cy='traceability_weight' align='right'>
          {weightToString(current_weight)}
        </PrimaryDetail>

        <DisclosureDetail align='center'>
          {sample && (
            <Icon
              onClick={onCollapsibleHandler}
              icon={collapsed ? 'caret-down' : 'caret-up'}
              className={'selected-lots__disclosure-icon'}
            />
          )}
        </DisclosureDetail>

        <IdentifierDetail>
          {local_identifier}

          {complies_eudr && <IdentifierDetailInfo>{'| EUDR'}</IdentifierDetailInfo>}
        </IdentifierDetail>

        <CenteredSecondaryDetail align='center'>{grade}</CenteredSecondaryDetail>

        <SecondaryDetail align='right' data-cy='traceability_number_of_bags'>
          {current_number_bags ? `${current_number_bags} Bags` : ''}
        </SecondaryDetail>
      </TraceabilityDetails>

      <SensorialAndOriginDetails>
        <PrimaryDetail>{certificate}</PrimaryDetail>
      </SensorialAndOriginDetails>

      <div className='l-item-stack l-item-stack--spaced'>
        <SpaceBetween>
          <ActionStack>
            <button
              onClick={() => setOverlay(!showOverlay)}
              type='button'
              className='button button--small button--ghost'
              data-cy='traceability_purchase_information'
            >
              {I18n.translate('traceability.purchase_information')}
            </button>

            <LotSetRemoveButton
              onConfirm={handleOnRemoveClick}
              lotSetIdentifier={local_identifier}
              disabled={!splitable}
              disabledText={splitable_info}
            />

            <SpecialMarkSelect
              defaultValue={special_mark_id}
              specialMarks={specialMarks}
              onChange={saveSpecialMark}
            />
          </ActionStack>
        </SpaceBetween>

        {traveled && <WarehouseInfo state={state} warehouseName={warehouse_name} />}
      </div>
    </TileList>
  );
};

export default SetTraceabilityHeader;
