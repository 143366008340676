import { computed, observable } from 'mobx';
import Routes from 'routes';

import StartShipmentType from '../types/startShipment';
import ShippingAdvice from './ShippingAdvice';
import ShippingAdviceType from '../types/shippingAdvice';
import PackingList from './PackingList';
import PackingListType from '../types/packingList';
import FinalizeService from '../services/FinalizeService';
import ShippingAdviceFinalizeService from 'exports/services/ShippingAdviceFinalizeService';
import PackingDeclarationType from 'exports/types/packingDeclaration';
import PackingDeclaration from './PackingDeclaration';
import Export from './Export';

class StartShipment {
  @observable public shippingAdvice: ShippingAdvice;
  @observable public packingList: PackingList;
  @observable public packingDeclaration: PackingDeclaration;

  constructor(
    {
      shippingAdvice,
      packingList,
      packingDeclaration,
      importerSecurityFilling,
      shipmentInstruction,
    }: StartShipmentType,
    export_: Export
  ) {
    const { requiredDocuments } = export_;

    const importerSecurityFillingRequired = requiredDocuments.includes(
      'importer_security_filling'
    );

    const shippingAdviceService = new ShippingAdviceFinalizeService<ShippingAdviceType>(
      export_,
      Routes.api_v1_export_shipping_advices_path,
      Routes.api_v1_shipping_advice_path,
      Routes.finalize_api_v1_shipping_advice_path,
      Routes.send_out_api_v1_export_shipping_advices_path
    );

    this.shippingAdvice = new ShippingAdvice(
      { finalized: false, ...shippingAdvice },
      shippingAdviceService
    );

    if (importerSecurityFilling && importerSecurityFillingRequired) {
      this.shippingAdvice.addDependency(importerSecurityFilling);
    }

    const packingListService = new FinalizeService<PackingListType>(
      export_,
      Routes.api_v1_export_packing_lists_path,
      Routes.api_v1_packing_list_path,
      Routes.finalize_api_v1_packing_list_path
    );

    this.packingList = new PackingList(
      { finalized: false, ...packingList },
      packingListService
    );

    this.packingList.addDependency(shipmentInstruction);
    this.shippingAdvice.addDependency(this.packingList);

    const packingDeclarationService = new FinalizeService<PackingDeclarationType>(
      export_,
      Routes.api_v1_export_packing_declarations_path,
      Routes.api_v1_packing_declaration_path,
      Routes.finalize_api_v1_packing_declaration_path
    );

    this.packingDeclaration = new PackingDeclaration(
      { finalized: false, ...packingDeclaration },
      packingDeclarationService
    );
  }

  @computed get allDocumentsFinalized() {
    return (
      this.shippingAdvice.finalized &&
      this.packingList.finalized &&
      this.packingDeclaration.finalized
    );
  }
}

export default StartShipment;
