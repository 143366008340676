export const equalityFilter = (value?: number | string, filterValue?: string) =>
  !filterValue || value === filterValue;

export const booleanFilter = (
  value?: boolean,
  filterValue?: string,
  alwaysTrueForFalse = true
) => {
  if (alwaysTrueForFalse && filterValue === 'false') return true;

  return value?.toString() === filterValue;
};

export const booleanFilterNotEqual = (
  value?: boolean,
  filterValue?: string,
  alwaysTrueForTrue = true
) => {
  if (alwaysTrueForTrue && filterValue === 'true') return true;
  return value?.toString() !== filterValue;
};
export const likeFilter = (value?: number | string, filterValue?: string) => {
  if (!filterValue) {
    return true;
  }

  if (!value && filterValue) {
    return false;
  }

  const parsedValue = String(value);

  return parsedValue.toLowerCase().includes(filterValue.toLowerCase());
};
