import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { AvailableCoffeeType } from 'opportunities/components/AvailableCoffee';

import { IField } from 'components/shared/form/IField';
import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';
import { Grid } from 'shared/ScrollableTable';
import SpotCoffee from './SpotCoffee';
import { customFetch } from 'utils/fetch';
import Routes from 'routes';

interface SpotCoffeeDetailsProps {
  fields: IField[];
  availableCoffee: Array<AvailableCoffeeType>;
  spotIcos: AvailableCoffeeType[];
  opportunityEstimatedKg: string;
  editMode?: boolean;
}

const SpotCoffeeDetails = ({
  availableCoffee,
  spotIcos,
  opportunityEstimatedKg,
  editMode,
}: SpotCoffeeDetailsProps) => {
  const [currentSpotIcos, setCurrentSpotIcos] = useState<AvailableCoffeeType[]>(
    spotIcos || []
  );
  const subtitle = opportunityEstimatedKg
    ? I18n.translate('contracts.form.estimated_kg_hint', {
        weight: opportunityEstimatedKg,
      })
    : '';

  useEffect(() => {
    if (spotIcos.length > 0 && editMode) {
      const updatedSpotIcos = [...spotIcos];
      const promises = spotIcos.map((ico, index) => {
        return customFetch(
          Routes.api_v1_fulfillment_get_spot_inventory_path({
            spot_inventory: {
              ico_identifier: ico?.identifier?.value,
            },
          }),
          undefined,
          'GET'
        ).then((response) => {
          if (response.status === 'success') {
            const { units: actualUnits } = response.response;
            updatedSpotIcos[index] = { ...ico, available_units: actualUnits };
          } else {
            updatedSpotIcos[index] = { ...ico, available_units: 0 };
          }
        });
      });

      Promise.all(promises).then(() => {
        setCurrentSpotIcos(updatedSpotIcos);
      });
    }
  }, [spotIcos]);

  return (
    <FieldSetWithTitle
      title={I18n.translate('contracts.form.coffee_details')}
      subtitle={subtitle}
    >
      <Grid>
        {availableCoffee && (
          <>
            {currentSpotIcos && (
              <table>
                <thead>
                  <tr>
                    <th>Info</th>
                    <th>Ico ID</th>
                    <th style={{ width: '25%' }}>Spot Ico Cost</th>
                    <th>Estimated Units</th>
                  </tr>
                </thead>
                <tbody>
                  {currentSpotIcos.map((ico) => (
                    <SpotCoffee
                      key={ico.identifier.value}
                      ico={ico}
                      editMode={editMode}
                    />
                  ))}
                </tbody>
              </table>
            )}
          </>
        )}
      </Grid>
    </FieldSetWithTitle>
  );
};

export default observer(SpotCoffeeDetails);
