import React from 'react';
import styled from 'styled-components';

import { VARIANT } from 'constants/inputTypes';

import BaseGroupComponent from 'components/groups/BaseGroup';
import TextWithSub from 'shared/TextWithSub/components/TextWithSub';
import Text from 'shared/TextWithSub/components/Text';
import Input from 'components/elements/Input';
import Checkbox from 'shared/Checkbox';
import ExcelsoInventoryModel from 'fulfillment/models/ExcelsoInventoryModel';
import { observer } from 'mobx-react';
import { DeliveryOrder } from 'fulfillment/types/types';
import ToolTip from 'shared/ToolTip/components/ToolTip';
import placeholder from 'utils/placeholder';

const CustomerCol = styled.td`
  width: 20%;
`;

const UnitCol = styled.td`
  width: 4%;
`;

const ExportStatusCol = styled.td`
  width: 8%;
`;

const ContractCol = styled.td`
  width: 8%;
`;

const PackagingCol = styled.td`
  width: 10%;
`;

const InputCol = styled.td`
  width: 20%;
`;

const InputWrapper = styled.div`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  input[type='text'] {
    width: 5em;
  }
`;

interface DeliveryOrderRowProps {
  excelsoInventoryModel: ExcelsoInventoryModel;
  deliveryOrder?: DeliveryOrder;
  index: number;
}

const DeliveryOrderRow = ({
  deliveryOrder,
  excelsoInventoryModel,
  index,
}: DeliveryOrderRowProps) => {
  const excelsoInventory = excelsoInventoryModel.gpExcelsoInventory;
  const icoData = excelsoInventory.ico_data;
  const customerName = placeholder(excelsoInventory.ico_data?.customer);
  const deliveryOrderItem = excelsoInventoryModel.deliveryOrderItem;
  const editMode = deliveryOrder !== undefined;
  const editable = editMode ? deliveryOrder?.editable : true;
  const unitFieldDisabled =
    !editable || !excelsoInventoryModel.checked || excelsoInventoryModel.reserved;

  const getFieldName = (attribute) =>
    `fulfillment_delivery_order[delivery_order_items_attributes][${index}][${attribute}]`;

  const handleOnQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    excelsoInventoryModel.setQuantity(event?.target.value);
  };

  return (
    <tr>
      <CustomerCol className='table-border'>
        <TextWithSub
          text={customerName}
          sub={excelsoInventory.ico_data?.destination_warehouse || ''}
          maxTextLength={200}
          overflow={true}
        />
      </CustomerCol>

      <ContractCol className='table-border'>
        <TextWithSub
          text={placeholder(icoData?.contract_identifier)}
          textClass='do--contract-id'
          sub={excelsoInventory.ico_identifier}
          subClass='do--ico'
          overflow={true}
        />

        {excelsoInventory.excelso_type === 'spot' && (
          <Text text={excelsoInventory.ico_data.internal_contract_identifier} />
        )}
      </ContractCol>

      <PackagingCol className='table-border'>
        <TextWithSub
          text={excelsoInventory.ico_data.packaging_type}
          sub={`${icoData.packaging_capacity} kg`}
          maxTextLength={200}
          overflow={true}
        />
      </PackagingCol>

      <CustomerCol className='table-border'>
        <TextWithSub
          text={placeholder(excelsoInventory.ico_data.mark)}
          sub={icoData.origin_country_code}
          maxTextLength={200}
          overflow={true}
        />
      </CustomerCol>

      <UnitCol className='table-border align-right'>
        <Text text={excelsoInventory.remaining_units} />
      </UnitCol>

      <ExportStatusCol className='table-border align-right'>
        <Text text={excelsoInventory.export_status} />
      </ExportStatusCol>

      <UnitCol className='table-border align-right'>
        <Text text={excelsoInventory.units} />
      </UnitCol>

      <InputCol className='align-center'>
        <InputWrapper>
          {excelsoInventory.bin ? (
            <>
              <BaseGroupComponent append='#' inline={true}>
                <Input
                  variant={VARIANT.INTEGER}
                  condensed={true}
                  min={1}
                  max={
                    excelsoInventory.excelso_type === 'spot'
                      ? excelsoInventory.contract_data.units
                      : excelsoInventory.units
                  }
                  value={excelsoInventoryModel.quantity}
                  disabled={unitFieldDisabled}
                  name={getFieldName('units_to_deliver')}
                  onChange={handleOnQuantityChange}
                />
              </BaseGroupComponent>

              <Checkbox
                checked={excelsoInventoryModel.checked}
                onChange={() => excelsoInventoryModel.toggleChecked()}
                disabled={!editable || excelsoInventoryModel.reserved}
              />

              {deliveryOrderItem?.id && (
                <input
                  type='hidden'
                  name={getFieldName('id')}
                  value={deliveryOrderItem.id}
                />
              )}

              {excelsoInventoryModel.checked && (
                <>
                  <input
                    type='hidden'
                    name={getFieldName('ico_with_secondary_number')}
                    value={excelsoInventory.ico_data.ico_with_secondary_number}
                  />

                  <input
                    type='hidden'
                    name={getFieldName('ico_identifier')}
                    value={excelsoInventory.ico_identifier}
                  />

                  <input
                    type='hidden'
                    name={getFieldName('bin')}
                    value={excelsoInventory.bin}
                  />

                  {excelsoInventory.excelso_type === 'spot' && (
                    <>
                      <input
                        type='hidden'
                        name={getFieldName('contract_identifier')}
                        value={excelsoInventory.ico_data.contract_identifier}
                      />
                      <input
                        type='hidden'
                        name={getFieldName('spot_contract_ico_id')}
                        value={excelsoInventory.ico_data.spot_contract_ico_id}
                      />
                    </>
                  )}
                </>
              )}

              {editMode && deliveryOrderItem?.id && !excelsoInventoryModel.checked && (
                <input type='hidden' name={getFieldName('_destroy')} value='true' />
              )}
            </>
          ) : (
            <ToolTip
              infos={[
                {
                  label: 'No BIN provided',
                  value:
                    'The ICO must have a BIN number for the Delivery Order to be created. Please notify the Accounting System team and IT.',
                },
              ]}
              title='No BIN provided for ICO'
            />
          )}
        </InputWrapper>
      </InputCol>
    </tr>
  );
};

export default observer(DeliveryOrderRow);
