import Icon from 'components/elements/Icon';
import { COLORS } from 'constants/colors';
import { formatExportDate } from 'exports/exportUtils';
import { observer } from 'mobx-react';
import React from 'react';
import OverlayBaseForm from 'shared/Overlay/components/OverlayBaseForm';
import OverlayPortal from 'shared/Overlay/components/OverlayPortal';
import OverlayWrapper from 'shared/Overlay/components/OverlayWrapper';
import TabWrapper from 'shared/Tabs';
import styled from 'styled-components';
import Export from '../models/Export';
import CloseIconWrapper from './CloseIconWrapper';
import ContainerInformation from './ContainerInformation';
import EndShipment from './formTabs/EndShipment';
import ShipmentSettings from './formTabs/ShipmentSettings';
import StartShipment from './formTabs/StartShipment';
import TabHeader from './TabHeader';

import Routes from 'routes';
import LoadingSpinner from 'shared/FileUploader/components/LoadingSpinner';
import { TabItem } from 'shared/Tabs/components/TabWrapper';

const ExportsOverlayContentWrapper = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
`;

const ExportsOverlayBaseForm = styled(OverlayBaseForm)`
  overflow: visible;
  position: relative;
  background-color: ${COLORS.MEDIUM_LIGHT_GRAY};
  box-shadow: none;
  min-width: 50rem;
`;

const ExportsInvoiceWrapper = styled.div`
  display: flex;
  flex-grow: 2;
  margin-right: 10px;
  button {
    margin-left: auto;
  }
`;

export interface FormPopupProps {
  onPopupClose: () => void;
  activeTabIndex?: number;
  export_: Export;
}

function FormPopup({
  onPopupClose,
  export_,
  activeTabIndex,
}: FormPopupProps): JSX.Element {
  const {
    shipmentSettings,
    startShipment,
    endShipment,
    willBeImported,
    endShipmentStateIcon,
    importDate,
  } = export_;

  const tabs: TabItem[] = [];

  if (shipmentSettings) {
    tabs.push({
      header: observer(() => (
        <TabHeader icon='list' stateIcon={export_.shipmentSettingsStateIcon} />
      )),
      body: observer(() => (
        <ShipmentSettings shipmentSettings={shipmentSettings} export_={export_} />
      )),
    });
  }

  if (shipmentSettings && startShipment) {
    tabs.push({
      header: observer(() => (
        <TabHeader
          icon='export'
          stateIcon={export_.startShipmentStateIcon}
          date={formatExportDate(export_.exportDate)}
        />
      )),
      body: observer(() => (
        <StartShipment
          shipmentSettings={shipmentSettings}
          startShipment={startShipment}
          export_={export_}
        />
      )),
    });
  }

  if (endShipment && willBeImported) {
    tabs.push({
      header: observer(() => (
        <TabHeader
          icon='import'
          stateIcon={endShipmentStateIcon}
          date={formatExportDate(importDate)}
        />
      )),
      body: observer(() => <EndShipment endShipment={endShipment} export_={export_} />),
    });
  }

  const closeOnOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.getAttribute('id') === 'js-overlay-wrapper') {
      onPopupClose();
    }
  };

  const uploadUrl = Routes.upload_api_v1_export_path(export_.id);
  const downloadUrl = Routes.download_all_export_download_path(export_.id);
  const disabledInvoiceButton = export_.invoiced || !export_.canBeInvoiced;
  const invoiceButtonText = export_.invoiced
    ? I18n.translate('buttons.invoiced')
    : I18n.translate('buttons.invoice');

  const handleInvoiceButton = () => {
    export_.updateInvoiced(true);
  };

  if (export_.isLoading) {
    return (
      <OverlayPortal>
        <OverlayWrapper>
          <LoadingSpinner size={32} />
        </OverlayWrapper>
      </OverlayPortal>
    );
  }

  return (
    <OverlayPortal>
      <OverlayWrapper onClick={closeOnOutsideClick} id='js-overlay-wrapper'>
        <ExportsOverlayBaseForm>
          <ExportsOverlayContentWrapper>
            <CloseIconWrapper>
              <Icon icon='cross' color='#006F94' onClick={onPopupClose} />
            </CloseIconWrapper>

            <ContainerInformation exportModel={export_} />

            <TabWrapper
              tabs={tabs}
              withArrows={true}
              renderWithIndex={activeTabIndex}
              uploadUrl={uploadUrl}
              downloadUrl={downloadUrl}
            >
              <ExportsInvoiceWrapper>
                <button
                  disabled={disabledInvoiceButton}
                  onClick={handleInvoiceButton}
                  className='button button--green'
                >
                  {invoiceButtonText}
                </button>
              </ExportsInvoiceWrapper>
            </TabWrapper>
          </ExportsOverlayContentWrapper>
        </ExportsOverlayBaseForm>
      </OverlayWrapper>
    </OverlayPortal>
  );
}

export default observer(FormPopup);
