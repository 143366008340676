import React from 'react';
import Inventory from 'inventoryReconciliations/type/inventory';
import { Provider } from 'mobx-react';
import InventoryReconciliationStore from 'inventoryReconciliations/store/InventoryReconciliationStore';
import InventoryReconcilation from 'inventoryReconciliations/components/InventoryReconcilation';
import { Pagy } from 'shared/Pagynation';

interface InventoryReconcilationContainerProps {
  inventories: Inventory[];
  pagy: Pagy;
}

const InventoryReconcilationContainer = ({
  inventories,
  pagy,
}: InventoryReconcilationContainerProps) => {
  return (
    <Provider
      inventoryReconciliationStore={new InventoryReconciliationStore(inventories, pagy)}
    >
      <InventoryReconcilation />
    </Provider>
  );
};

export default InventoryReconcilationContainer;
