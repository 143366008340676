import React from 'react';
import { useStores } from 'utils/useStores';
import InventoryRow from './InventoryRow';

const InventoryTable = (): JSX.Element => {
  const { inventoryReconciliationStore } = useStores();
  const { inventories } = inventoryReconciliationStore;

  return (
    <table>
      <thead>
        <tr>
          <th className='table-border'></th>
          <th className='table-border'>
            {I18n.translate('inventory_reconciliations.index.main_identifier')}
          </th>

          <th className='table-border'>
            {I18n.translate('inventory_reconciliations.index.office')}
          </th>

          <th className='table-border'>
            {I18n.translate('inventory_reconciliations.index.location')}
          </th>

          <th className='table-border'>
            {I18n.translate('inventory_reconciliations.index.quality')}
          </th>

          <th className='table-border'>
            {I18n.translate('inventory_reconciliations.index.weight')}
          </th>

          <th className='table-border'>
            {I18n.translate('inventory_reconciliations.index.difference_reason')}
          </th>

          <th className='table-border'></th>
        </tr>
      </thead>
      <tbody>
        {inventories.map((inventory) => (
          <InventoryRow key={inventory.mainIdentifier} inventory={inventory} />
        ))}
      </tbody>
    </table>
  );
};

export default InventoryTable;
