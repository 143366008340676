import React from 'react';
import Routes from 'routes';

import { LotTraceability } from 'traceability/types/traceability';
import SelectOptionArray from 'types/model/selectOption';
import { TileList } from 'shared/Tile';
import LotPurchaseInformationOverlay from './LotPurchaseInformationOverlay';
import { TraceabilityDetails } from './Details';
import TypeBadge from './TypeBadge';
import {
  PrimaryDetail,
  DisclosureDetail,
  DateDetail,
  CenteredSecondaryDetail,
  IdentifierDetail,
  SecondaryDetail,
  TooltipWrapper,
  IdentifierDetailInfo,
} from './Detail';
import Icon from 'components/elements/Icon';
import RegradeArrow from './RegradeArrow';

import SensorialAnalysisBadge from 'sets/components/SensorialAnalysisBadge';
import SpaceBetween from 'components/elements/SpaceBetween';
import SpecialMarkSelect from './SpecialMarkSelect';
import { customFetch } from 'utils/fetch';
import { LOT_STATE } from 'types/model/lot';
import LotAnnulButton from './LotAnnulButton';
import WarehouseInfo from './WarehouseInfo';
import { weightToString } from 'utils/weight';
import { SensorialAnalysisDetail, SensorialAndOriginDetails } from './SensorialDetails';
import ActionStack from './ActionStack';
import ToolTip, { Info } from 'shared/ToolTip/components/ToolTip';

export interface LotTraceabilityHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  onCollapsibleHandler: (event: React.MouseEvent) => any;
  collapsed: boolean;
  traceability: LotTraceability;
  specialMarks: SelectOptionArray;
}

function LotTraceabilityHeader({
  traceability,
  traceability: {
    id,
    type,
    created_at,
    grade,
    original_grade,
    quality,
    original_quality,
    score,
    original_score,
    current_weight,
    local_identifier,
    current_number_bags,
    sensorial_analysis,
    special_mark_id,
    producer,
    municipality,
    sample,
    state,
    traveled,
    warehouse_name,
    complies_eudr,
  },
  collapsed,
  onCollapsibleHandler,
  specialMarks,
  ...props
}: LotTraceabilityHeaderProps) {
  const [showOverlay, setOverlay] = React.useState(false);

  const saveSpecialMark = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const path = Routes.api_v1_lot_path;

    customFetch(path(id), {
      special_mark_id: event.target.value,
    });
  };

  const overlayProps = {
    onPrimaryClick: () => setOverlay(!showOverlay),
  };

  const isRegraded = grade && original_grade;
  const tooltipInfos: Info[] = [];

  if (isRegraded) {
    if (original_score) {
      tooltipInfos.push({
        label: I18n.translate('traceability.grade_tooltip.initial_score'),
        value: original_score.toString(),
      });
    }
    if (original_quality) {
      tooltipInfos.push({
        label: I18n.translate('traceability.grade_tooltip.initial_quality'),
        value: original_quality,
      });
    }
    if (original_grade) {
      tooltipInfos.push({
        label: I18n.translate('traceability.grade_tooltip.initial_grade'),
        value: original_grade.toString(),
      });
    }
    if (score) {
      tooltipInfos.push({
        label: I18n.translate('traceability.grade_tooltip.new_score'),
        value: score.toString(),
      });
    }
    if (quality) {
      tooltipInfos.push({
        label: I18n.translate('traceability.grade_tooltip.new_quality'),
        value: quality,
      });
    }
    if (grade) {
      tooltipInfos.push({
        label: I18n.translate('traceability.grade_tooltip.new_grade'),
        value: grade.toString(),
      });
    }
  }

  return (
    <TileList {...props}>
      {showOverlay && (
        <LotPurchaseInformationOverlay {...overlayProps} traceability={traceability} />
      )}

      <TraceabilityDetails>
        <TypeBadge type={type}>{type}</TypeBadge>

        <DateDetail>{created_at}</DateDetail>

        <PrimaryDetail data-cy='traceability_quality' align='center'>
          {quality}
        </PrimaryDetail>

        <PrimaryDetail data-cy='traceability_weight' align='right'>
          {weightToString(current_weight)}
        </PrimaryDetail>

        <DisclosureDetail align='center'>
          {sample && (
            <Icon
              onClick={onCollapsibleHandler}
              icon={collapsed ? 'caret-down' : 'caret-up'}
              className={'selected-lots__disclosure-icon'}
            />
          )}
        </DisclosureDetail>

        <IdentifierDetail>
          {local_identifier}

          {complies_eudr && <IdentifierDetailInfo>{'| EUDR'}</IdentifierDetailInfo>}
        </IdentifierDetail>

        <CenteredSecondaryDetail align='center'>
          {isRegraded && <RegradeArrow grade={grade} originalGrade={original_grade} />}

          {grade}
        </CenteredSecondaryDetail>

        <SecondaryDetail align='right' data-cy='traceability_number_of_bags'>
          {current_number_bags ? `${current_number_bags} Bags` : ''}
        </SecondaryDetail>

        {isRegraded && (
          <TooltipWrapper>
            <ToolTip
              title={I18n.translate('traceability.grade_tooltip.title')}
              infos={tooltipInfos}
            />
          </TooltipWrapper>
        )}
      </TraceabilityDetails>

      <SensorialAndOriginDetails>
        {sensorial_analysis && (
          <SensorialAnalysisDetail>
            {sensorial_analysis.values
              .filter(({ test }) => !!test.match(/(Body|Overall)/))
              .map(({ test, value }, index) => (
                <SensorialAnalysisBadge
                  key={index}
                  kind={test.toLowerCase()}
                  label={value}
                />
              ))}
          </SensorialAnalysisDetail>
        )}

        {producer && municipality && (
          <PrimaryDetail data-cy='traceability_producer'>
            <strong>{municipality}</strong> – {producer}
          </PrimaryDetail>
        )}
      </SensorialAndOriginDetails>

      <div className='l-item-stack l-item-stack--spaced'>
        <SpaceBetween>
          <ActionStack>
            <button
              onClick={() => setOverlay(!showOverlay)}
              type='button'
              className='button button--small button--ghost'
              data-cy='traceability_purchase_information'
            >
              {I18n.translate('traceability.purchase_information')}
            </button>

            <SpecialMarkSelect
              defaultValue={special_mark_id}
              specialMarks={specialMarks}
              onChange={saveSpecialMark}
            />
          </ActionStack>
          <ActionStack>
            {state === LOT_STATE.STOCKED && (
              <LotAnnulButton id={id} identifier={local_identifier} />
            )}
            <a
              href={Routes.print_purchase_price_approval_lot_path(id)}
              target='_blank'
              rel='noreferrer'
              className='button button--small button--ghost'
            >
              {I18n.translate('fixed_price_contracts.pending.print')}
            </a>
          </ActionStack>
        </SpaceBetween>

        {traveled && <WarehouseInfo state={state} warehouseName={warehouse_name} />}
      </div>
    </TileList>
  );
}

export default LotTraceabilityHeader;
