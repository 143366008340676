import React from 'react';
import Overlay from 'shared/Overlay';
import InputGroupComponent from 'components/groups/InputGroup';
import styled from 'styled-components';
import Inventory from 'inventoryReconciliations/type/inventory';
import TextAreaGroupComponent from 'components/groups/TextareaGroup';
import COLORS from 'constants/colors';

const InventoryChangeOverlayBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 1.625em;
`;

const FullWidthTextArea = styled(TextAreaGroupComponent)`
  grid-column: 1 / -1;
`;

interface InventoryChangeOverlayProps {
  observations: string;
  setObservations: (observations: string) => void;
  onApproveClick: () => void;
  onRejectClick: () => void;
  onCancelClick: () => void;
  inventory: Inventory;
}

const InventoryChangeOverlay = ({
  observations,
  setObservations,
  onApproveClick,
  onRejectClick,
  onCancelClick,
  inventory,
}: InventoryChangeOverlayProps): JSX.Element => {
  const differences = inventory.differences;
  const netsuiteLocation = differences?.location?.error
    ? 'Error'
    : differences.location?.name || inventory.location;
  const netsuiteWeight = differences.weight?.value || inventory.weight;

  const netsuiteLocationStyle = differences.location
    ? { color: COLORS.RED, fontWeight: 900 }
    : undefined;

  const netsuiteWeightStyle = differences.weight
    ? { color: COLORS.RED, fontWeight: 900 }
    : undefined;

  const disableCondition =
    !observations || differences?.location?.error || !inventory.canConcile;

  return (
    <Overlay
      id={inventory.mainIdentifier}
      title={`${I18n.translate('inventory_reconciliations.index.title')} ${
        inventory.mainIdentifier
      }`}
      primaryDisabled={disableCondition}
      secondaryDisabled={disableCondition}
      primaryText={`${I18n.translate('buttons.approve')}`}
      secondaryText={I18n.translate('buttons.reject')}
      thirdText={I18n.translate('buttons.close')}
      onPrimaryClick={onApproveClick}
      onSecondaryClick={onRejectClick}
      onThirdClick={onCancelClick}
      primaryClassName='button button--green'
      secondaryClassName='button button--red'
    >
      <InventoryChangeOverlayBody>
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.atlas_location')}
          disabled={true}
          value={inventory.location}
        />
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.netsuite_location')}
          disabled={true}
          style={netsuiteLocationStyle as React.CSSProperties}
          value={netsuiteLocation}
        />
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.atlas_quality')}
          disabled={true}
          value={inventory.quality}
        />
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.netsuite_quality')}
          disabled={true}
          value={inventory.quality}
        />
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.atlas_weight')}
          disabled={true}
          value={inventory.weight}
        />
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.netsuite_weight')}
          disabled={true}
          value={netsuiteWeight}
          style={netsuiteWeightStyle as React.CSSProperties}
        />
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.weight_difference')}
          disabled={true}
          value={netsuiteWeight - inventory.weight}
        />
        <InputGroupComponent
          label={I18n.translate('inventory_reconciliations.form.user')}
          disabled={true}
          value={inventory.user}
        />
        <FullWidthTextArea
          label={I18n.translate('inventory_reconciliations.form.observations')}
          value={observations}
          onChange={(e) => setObservations(e.target.value)}
        />
      </InventoryChangeOverlayBody>
    </Overlay>
  );
};

export default InventoryChangeOverlay;
