import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState, useRef } from 'react';

import { SelectOption } from 'types/model/selectOption';

import FieldSetWithTitle from 'shared/FieldSetWithTitle/components/FieldSetWithTitle';

import Grid from 'components/elements/Grid';

import CustomerOrLeadComponent from './CustomerOrLeadComponent';
import { AvailableCoffeeType } from 'opportunities/components/AvailableCoffee';
import { OpportunityCoffee } from './segments/OpportunityCoffee';
import OpportunitySpotCoffee from './segments/OpportunitySpotCoffee';
import { OpportunityLost } from './segments/OpportunityLost';
import { OpportunityTextAreaInput } from './OpportunityFields';
import OpportunityContract from './segments/OpportunityContract';
import { OpportunityDelivery } from './segments/OpportunityDelivery';
import { IField } from 'components/shared/form/IField';
import Routes from 'routes';
import { customFetch } from 'utils/fetch';

export interface OpportunityFormProps {
  fields: IField[];
  availableCoffee: AvailableCoffeeType[];
  selectedIcos: AvailableCoffeeType[];
  currentOfficeCountryId: number;
  updateable?: boolean;
}

const OpportunityFormComponent: React.FC<OpportunityFormProps> = ({
  fields,
  selectedIcos,
  updateable,
}) => {
  const getField = (name) => fields.find((field) => field.name == name);
  const contractTypeField = useMemo(() => getField('contract_type'), []);
  const [contractType, setContractType] = useState(contractTypeField?.value || '');
  const priceTypeField = useMemo(() => getField('price_type'), []);
  const [priceType, setPriceType] = useState(priceTypeField?.value || '');
  const fixedPriceField = useMemo(() => getField('fixed_price'), []);
  const differentialField = useMemo(() => getField('differential'), []);
  const probabilityField = useMemo(() => getField('probability'), []);
  const qualityIdField = useMemo(() => getField('quality_id'), []);
  const certificateIdField = useMemo(() => getField('certificate_id'), []);
  const estimatedKgField = useMemo(() => getField('estimated_kg'), []);
  const estimatedDeliveryDateField = useMemo(
    () => getField('estimated_delivery_date'),
    []
  );
  const observationsField = useMemo(() => getField('observations'), []);
  const [observations, setObservations] = useState(observationsField?.value || '');
  const originCountryIdsField = useMemo(() => getField('origin_countries'), []);
  const [originCountryIds, setOriginCountryIds] = useState(
    originCountryIdsField?.value || ''
  );
  const destinationPortIdField = useMemo(() => getField('destination_port_id'), []);
  const markIdField = useMemo(() => getField('mark_id'), []);
  const lostOpportunityReasonIdField = useMemo(
    () => getField('lost_opportunity_reason_id'),
    []
  );

  const [isSpotSelectionDisabled, setSpotSelectionDisabled] = useState(false);

  const customerIdField = useMemo(() => getField('customer_id'), []);
  const customerLeadIdField = useMemo(() => getField('customer_lead_id'), []);
  const opportunitySourceIdField = useMemo(() => getField('opportunity_source_id'), []);

  const thirdPartyShipperField = useMemo(() => getField('third_party_shipper_id'), []);
  const estimatedUnitsField = useMemo(() => getField('estimated_units'), []);
  const originWarehouseField = useMemo(() => getField('origin_warehouse_id'), []);
  const priceTypeFilteredOptions = ['spot', 'local'].includes(contractType)
    ? priceTypeField?.options?.filter((opt: SelectOption) => opt.name == 'Fixed')
    : priceTypeField?.options;

  const isSpot = contractType == 'spot';
  const isLocal = contractType == 'local';

  const [selectedSpotIcos, setSelectedSpotIcos] = useState<AvailableCoffeeType[]>(
    selectedIcos || []
  );

  const addSelectedIco = (spotIco) => {
    if (spotIco.checked) {
      if (selectedIcos) addOpportunityIco(spotIco);
      setSelectedSpotIcos([...selectedSpotIcos, spotIco]);
    } else {
      setSelectedSpotIcos(selectedSpotIcos.filter((ico) => ico.id !== spotIco.id));
    }
  };

  const addOpportunityIco = (spotIco) => {
    const foundIco = selectedIcos.find((ico) => ico.id === spotIco.id);
    spotIco.opportunity_ico_id = foundIco?.opportunity_ico_id;
  };

  const updateIcoUnits = (icoId, value) => {
    const icoIndex = selectedSpotIcos.findIndex((item) => item.id === icoId);

    if (icoIndex !== -1) {
      const newSelectedSpotIcos = [...selectedSpotIcos];
      newSelectedSpotIcos[icoIndex].desired_units = value;
      setSelectedSpotIcos(newSelectedSpotIcos);
    }
  };

  const incotermField = useMemo(() => getField('incoterm'), []);
  const [incoterm, setIncoterm] = useState(incotermField?.value || '');

  const sampleNeededField = useMemo(() => getField('sample_needed'), []);
  const [sampleNeeded, setSampleNeeded] = useState(sampleNeededField?.value || false);

  const onLostOpportunityChangeHandler = (value: boolean) => {
    setSpotSelectionDisabled(value);
  };

  const prevSelectedSpotIcos = useRef<AvailableCoffeeType[]>([]);
  const icoCache = useRef<{ [key: string]: AvailableCoffeeType }>({});

  useEffect(() => {
    if (
      selectedSpotIcos.length > 0 &&
      prevSelectedSpotIcos.current !== selectedSpotIcos
    ) {
      const updatedSpotIcos = [...selectedSpotIcos];
      const promises = selectedSpotIcos.map((ico, index) => {
        const cacheKey = ico.identifier.value;

        if (icoCache.current[cacheKey]) {
          // Use the cached value if available
          updatedSpotIcos[index] = icoCache.current[cacheKey];
          return Promise.resolve();
        }

        // Fetch data if not cached
        return customFetch(
          Routes.api_v1_fulfillment_get_spot_inventory_path({
            spot_inventory: {
              ico_identifier: ico.identifier.value,
            },
          }),
          undefined,
          'GET'
        ).then((response) => {
          if (response.status === 'success') {
            const { units: actualUnits } = response.response;
            const updatedIco = { ...ico, available_units: actualUnits };
            updatedSpotIcos[index] = updatedIco;
            icoCache.current[cacheKey] = updatedIco; // Cache the full structure
          } else {
            updatedSpotIcos[index] = { ...ico, available_units: 0 };
            icoCache.current[cacheKey] = { ...ico, available_units: 0 }; // Cache the full structure
          }
        });
      });

      Promise.all(promises).then(() => {
        prevSelectedSpotIcos.current = updatedSpotIcos;
        setSelectedSpotIcos(updatedSpotIcos);
      });
    }
  }, [selectedSpotIcos]);

  return (
    <>
      <OpportunityContract
        contractTypeField={contractTypeField}
        contractType={contractType}
        setContractType={setContractType}
        priceTypeField={priceTypeField}
        priceType={priceType}
        setPriceType={setPriceType}
        priceTypeOptions={priceTypeFilteredOptions}
        probabilityField={probabilityField}
        fixedPriceField={fixedPriceField}
        differentialField={differentialField}
        incotermField={incotermField}
        incoterm={incoterm}
        setIncoterm={setIncoterm}
        sampleNeededField={sampleNeededField}
        sampleNeeded={sampleNeeded}
        setSampleNeeded={setSampleNeeded}
      />
      <FieldSetWithTitle title={I18n.translate('opportunities.form.customer')}>
        <Grid>
          <CustomerOrLeadComponent
            customers={customerIdField?.options}
            customerLeads={customerLeadIdField?.options}
            readOnly={customerLeadIdField?.readonly || false}
            selectedCustomerId={customerIdField?.value || undefined}
            selectedCustomerLeadId={customerLeadIdField?.value || undefined}
            opportunitySourceIdField={opportunitySourceIdField}
            errors={customerIdField?.errors}
          />
        </Grid>
      </FieldSetWithTitle>

      {isSpot ? (
        <OpportunitySpotCoffee
          selectedSpotIcos={selectedSpotIcos}
          setSelectedSpotIcos={setSelectedSpotIcos}
          initialSelectedIcos={selectedIcos || []}
          updateIcoUnits={updateIcoUnits}
          onSelect={addSelectedIco}
          estimatedUnitsField={estimatedUnitsField}
          disabled={isSpotSelectionDisabled}
          originWarehouseField={originWarehouseField}
        />
      ) : (
        <OpportunityCoffee
          originCountryIdsField={originCountryIdsField}
          originCountryIds={originCountryIds}
          setOriginCountryIds={setOriginCountryIds}
          markIdField={markIdField}
          qualityIdField={qualityIdField}
          certificateIdField={certificateIdField}
          estimatedKgField={estimatedKgField}
          contractType={contractType}
          thirdPartyShipperField={thirdPartyShipperField}
        />
      )}

      <OpportunityDelivery
        estimatedDeliveryDateField={estimatedDeliveryDateField}
        destinationPortIdField={destinationPortIdField}
        destinationPortHidden={isSpot || isLocal}
      />

      <FieldSetWithTitle title={''}>
        <OpportunityTextAreaInput
          field={observationsField}
          value={observations}
          onChange={(e) => setObservations(e.target.value)}
        />
      </FieldSetWithTitle>

      <OpportunityLost
        onChange={onLostOpportunityChangeHandler}
        lostOpportunityReasonIdField={lostOpportunityReasonIdField}
      />

      <FieldSetWithTitle title=''>
        <div className='l-distribute-l-r'>
          <a
            className='button button--gray'
            data-cy='opportunities_form_cancel'
            href={Routes.opportunities_path()}
          >
            {I18n.translate('buttons.cancel')}
          </a>
          {!!updateable && (
            <input
              type='submit'
              className='button'
              data-cy='opportunities_form_submit'
              value={I18n.translate('buttons.save')}
            />
          )}
        </div>
      </FieldSetWithTitle>
    </>
  );
};

export default observer(OpportunityFormComponent);
