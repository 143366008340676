import Inventory from 'inventoryReconciliations/type/inventory';
import { Pagy } from 'shared/Pagynation';
import { action, observable } from 'mobx';

class InventoryReconciliationStore {
  @observable inventories: Inventory[];
  @observable selectedInventories: Inventory[] = [];
  pagy: Pagy;

  constructor(inventories: Inventory[], pagy: Pagy) {
    this.inventories = inventories;
    this.pagy = pagy;
  }

  @action addInventory(inventory: Inventory) {
    this.selectedInventories.push(inventory);
  }

  @action removeInventory(inventory: Inventory) {
    this.selectedInventories = this.selectedInventories.filter(
      (i) => i.id !== inventory.id
    );
  }

  serialize(rawInventories: Inventory[], action: string, observations: string) {
    return {
      action,
      observations,
      inventories: rawInventories.map((i) => ({
        id: i.id,
        type: i.type,
        differences: i.differences,
      })),
    };
  }
}

export default InventoryReconciliationStore;
