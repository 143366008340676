import { observable, computed, action } from 'mobx';

import ShippingAdviceType from '../types/shippingAdvice';
import FinalizeableResource from './FinalizableResource';
import Routes from 'routes';

class ShippingAdvice extends FinalizeableResource<ShippingAdviceType> {
  @observable can_be_exported?: boolean;
  @observable finalized: boolean;
  @observable container_number?: string;
  @observable etd?: string;
  @observable eta?: string;
  @observable date_of_arrival?: string;

  @action protected updateAttributes({
    id,
    finalized,
    can_be_exported,
    container_number,
    etd,
    eta,
    date_of_arrival,
  }: ShippingAdviceType) {
    this.finalized = finalized;

    this.id = id;
    this.container_number = container_number;
    this.etd = etd;
    this.eta = eta;
    this.date_of_arrival = date_of_arrival;
    this.can_be_exported = can_be_exported;
  }

  @computed get isFinalizable() {
    return (
      this.dependenciesFinalized &&
      this.hasValidAttributeValue('container_number', 'etd', 'eta', 'date_of_arrival')
    );
  }

  @computed get downloadUrl() {
    return this.id ? Routes.download_api_v1_shipping_advice_path(this.id) : undefined;
  }

  @action public sendOut = async (payload: ShippingAdviceType): Promise<void> => {
    const attributes = await this['httpLayerService']['sendOut'](
      this['httpLayerService']['export_']['id'],
      payload
    );

    this.updateAttributes(attributes);
  };
}

export default ShippingAdvice;
