import { observer } from 'mobx-react';
import React from 'react';

import useStores from 'utils/useStores';
import { useFilterContext } from 'utils/filterContext';

import CoffeeItemRow, { VarietyCol, WeightCol } from './CoffeeItemRow';
import ItemColumn from './ItemColumn';
import FilterBar from 'components/elements/FilterBar';
import InputGroupComponent from 'components/groups/InputGroup';
import SelectGroup from 'components/groups/SelectGroup';
import AvailableMillingOutput from 'availableCoffee/models/AvailableMillingOutput';
import AvailableCoffeItem from 'availableCoffee/models/AvailableCoffeItem';
import MillingOutputRow from './MillingOutputRow';
import Checkbox from 'shared/Checkbox';
import styled from 'styled-components';

const WrapperCheckbox = styled.div`
  grid-column: 1;
`;
const AvailableCoffeTable = () => {
  const { availableCoffeeStore } = useStores();
  const { productionOrderType } = availableCoffeeStore;
  const { filters, updateFilter } = useFilterContext();

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => updateFilter(event.target.name, event.target.value);

  const filteredItems = React.useMemo(() => {
    return availableCoffeeStore.items.filter(
      (item) =>
        Object.entries(filters).every(([key, { filter, value }]) => {
          return value ? filter(item.attributes[key], value) : true;
        }) && parseFloat(item.quantity) > 0
    );
  }, [filters]);

  const renderItemRows = () => {
    return filteredItems.map((item) => {
      if (item instanceof AvailableCoffeItem) {
        return <CoffeeItemRow key={item.attributes.local_identifier} item={item} />;
      } else if (item instanceof AvailableMillingOutput) {
        return <MillingOutputRow key={item.attributes.local_identifier} item={item} />;
      }
    });
  };

  return (
    <>
      <FilterBar columns={'repeat(auto-fit, minmax(100px, 1fr))'}>
        <InputGroupComponent
          name='local_identifier'
          type='text'
          noMargin={true}
          placeholder={I18n.translate('available_coffee.search_placeholder')}
          onChange={handleOnChange}
        />
        <SelectGroup
          name='warehouse'
          onChange={handleOnChange}
          noMargin={true}
          options={availableCoffeeStore.warehouses}
          value={filters['warehouse'].value}
          placeholder={I18n.translate('available_coffee.select_a_warehouse')}
        />

        {availableCoffeeStore.qualities.length > 0 && (
          <SelectGroup
            name='quality'
            onChange={handleOnChange}
            noMargin={true}
            options={availableCoffeeStore.qualities}
            value={filters['quality'].value}
            placeholder={I18n.translate('available_coffee.select_a_quality')}
          />
        )}

        {availableCoffeeStore.varieties.length > 0 && (
          <SelectGroup
            name='variety'
            onChange={handleOnChange}
            noMargin={true}
            options={availableCoffeeStore.varieties}
            value={filters['variety'].value}
            placeholder={I18n.translate('available_coffee.select_a_variety')}
          />
        )}

        <SelectGroup
          name='process_type'
          onChange={handleOnChange}
          noMargin={true}
          options={availableCoffeeStore.processTypes}
          value={filters['process_type'].value}
          placeholder={I18n.translate('available_coffee.select_a_process_type')}
        />

        {filters['complies_eudr'] && (
          <WrapperCheckbox>
            <Checkbox
              icon='tick'
              label={I18n.translate('available_coffee.eudr_validation_skipped')}
              onChange={(checked) => {
                availableCoffeeStore.setEudrValidationSkipped(checked);
                updateFilter('complies_eudr', checked.toString());
              }}
              checked={filters['complies_eudr'].value === 'true'}
            />
          </WrapperCheckbox>
        )}
      </FilterBar>

      <table className='table--full-border'>
        <thead>
          <tr>
            <ItemColumn as='th' className='align-left'>
              {I18n.translate('available_coffee.id_warehouse')}
            </ItemColumn>

            {productionOrderType === 'vanilla' && (
              <th className='align-left'>{I18n.translate('available_coffee.quality')}</th>
            )}

            <VarietyCol as='th'>
              {productionOrderType === 'vanilla' &&
                I18n.translate('available_coffee.process_type_certification')}

              {productionOrderType === 'caracol' &&
                I18n.translate('available_coffee.certificate_ico_identifier')}
            </VarietyCol>

            {productionOrderType === 'vanilla' && (
              <th>{I18n.translate('available_coffee.price_factor')}</th>
            )}

            {productionOrderType === 'vanilla' && (
              <th> {availableCoffeeStore.millingFactorTitle} </th>
            )}

            <WeightCol as='th'>
              {I18n.translate('available_coffee.available_total_weight')}
            </WeightCol>

            {productionOrderType === 'vanilla' && (
              <th>{I18n.translate('available_coffee.reserved')}</th>
            )}

            <th>{I18n.translate('available_coffee.quantity_to_use')}</th>
          </tr>
        </thead>

        <tbody>{renderItemRows()}</tbody>
      </table>
    </>
  );
};

export default observer(AvailableCoffeTable);
