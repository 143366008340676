/**
 * File generated by js-routes 2.2.2
 * Based on Rails 6.1.6.1 routes of CaravelaAtlas::Application
 */
((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !null || !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    object[part] = routes;
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{
  /**
   * Generates rails route to
   * /accept_price(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  accept_price_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"accept_price"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /accept_price/accept/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  accept_price_accept_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accept_price"],[2,[7,"/"],[2,[6,"accept"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /accept_price/recup/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  accept_price_recup_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"accept_price"],[2,[7,"/"],[2,[6,"recup"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customers/:id/account_integration(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  account_integration_basedata_customer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"account_integration"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/contracts/:id/add_mark(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  add_mark_api_v1_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"add_mark"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:id/adjust_weight(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  adjust_weight_api_v1_sample_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"adjust_weight"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/analyse_and_adjust(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  analyse_and_adjust_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"analyse_and_adjust"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/lots/:id/annul(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  annul_api_v1_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"annul"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/business/v1/fulfillment/delivery_orders/:delivery_order_id/accept(.:format)
   * @param {any} delivery_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_business_v1_fulfillment_delivery_order_accept_path: __jsr.r({"delivery_order_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"business"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"delivery_order_id"],[2,[7,"/"],[2,[6,"accept"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/business/v1/fulfillment/delivery_orders/:delivery_order_id/reject(.:format)
   * @param {any} delivery_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_business_v1_fulfillment_delivery_order_reject_path: __jsr.r({"delivery_order_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"business"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"delivery_order_id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/availability_notices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_availability_notice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"availability_notices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/bundles/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_bundle_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/contract_prices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_contract_prices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contract_prices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/customer_samples/customer_sample_values/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_customer_samples_customer_sample_value_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer_samples"],[2,[7,"/"],[2,[6,"customer_sample_values"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/customer_samples/customer_sample_values(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_customer_samples_customer_sample_values_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer_samples"],[2,[7,"/"],[2,[6,"customer_sample_values"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/customer_samples/opportunities(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_customer_samples_opportunities_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer_samples"],[2,[7,"/"],[2,[6,"opportunities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/decaf/decaf_orders/:decaf_order_id/humidity_analyses(.:format)
   * @param {any} decaf_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_decaf_decaf_order_humidity_analyses_path: __jsr.r({"decaf_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"decaf_orders"],[2,[7,"/"],[2,[3,"decaf_order_id"],[2,[7,"/"],[2,[6,"humidity_analyses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/destination_ports/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_destination_port_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"destination_ports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/drivers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_driver_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/availability_notices(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_availability_notices_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"availability_notices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/ico_lists(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_ico_lists_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"ico_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/importer_security_fillings(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_importer_security_fillings_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"importer_security_fillings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/packing_declarations(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_packing_declarations_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"packing_declarations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/packing_lists(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_packing_lists_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"packing_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/responsibility_letters(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_responsibility_letters_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"responsibility_letters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/shipment_instructions(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_shipment_instructions_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"shipment_instructions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/shipping_advices(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_shipping_advices_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"shipping_advices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/transit_guides(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_export_transit_guides_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"transit_guides"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/shipping_line_contracts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_exports_shipping_line_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"shipping_line_contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/third_party_imports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_exports_third_party_imports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"third_party_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/farms/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_farm_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"farms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fractions/:fraction_id/fraction_reprocesses/:id(.:format)
   * @param {any} fraction_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_fraction_fraction_reprocess_path: __jsr.r({"fraction_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fractions"],[2,[7,"/"],[2,[3,"fraction_id"],[2,[7,"/"],[2,[6,"fraction_reprocesses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fractions/:fraction_id/fraction_reprocesses(.:format)
   * @param {any} fraction_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_fraction_fraction_reprocesses_path: __jsr.r({"fraction_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fractions"],[2,[7,"/"],[2,[3,"fraction_id"],[2,[7,"/"],[2,[6,"fraction_reprocesses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_fulfillment_delivery_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/get_spot_inventory(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_fulfillment_get_spot_inventory_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"get_spot_inventory"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/ico_list(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_fulfillment_ico_list_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"ico_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/ico_lists/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_ico_list_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ico_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/importer_security_fillings/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_importer_security_filling_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"importer_security_fillings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/inventory_reconciliations/reconcile_inventories(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_inventory_reconciliations_reconcile_inventories_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"inventory_reconciliations"],[2,[7,"/"],[2,[6,"reconcile_inventories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/lots/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/lots/:lot_id/final_prices/:id(.:format)
   * @param {any} lot_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_lot_final_price_path: __jsr.r({"lot_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"final_prices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/lot_sets/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_lot_set_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"lot_sets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/lot_sets/:lot_set_id/lots/:id(.:format)
   * @param {any} lot_set_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_lot_set_lot_path: __jsr.r({"lot_set_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"lot_sets"],[2,[7,"/"],[2,[3,"lot_set_id"],[2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/milling_orders/:milling_order_id/fractions/:id(.:format)
   * @param {any} milling_order_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_milling_order_fraction_path: __jsr.r({"milling_order_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"fractions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/milling_orders/:milling_order_id/fractions(.:format)
   * @param {any} milling_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_milling_order_fractions_path: __jsr.r({"milling_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"fractions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/packing_declarations/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_packing_declaration_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"packing_declarations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/packing_lists/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_packing_list_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"packing_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/producers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_producer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"producers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/production_logs/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_production_log_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"production_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/production_logs(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_production_logs_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"production_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/production_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_production_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"production_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/responsibility_letters/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_responsibility_letter_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"responsibility_letters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:sample_id/humidity_analyses(.:format)
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_sample_humidity_analyses_path: __jsr.r({"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"humidity_analyses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:sample_id/physical_analyses(.:format)
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_sample_physical_analyses_path: __jsr.r({"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"physical_analyses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:sample_id/physical_analyses/:id(.:format)
   * @param {any} sample_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_sample_physical_analysis_path: __jsr.r({"sample_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"physical_analyses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:sample_id/roastings/:id(.:format)
   * @param {any} sample_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_sample_roasting_path: __jsr.r({"sample_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"roastings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:sample_id/roastings(.:format)
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_sample_roastings_path: __jsr.r({"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"roastings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/create_arrival_sample(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_create_arrival_sample_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"create_arrival_sample"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/create_mill_sample(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_create_mill_sample_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"create_mill_sample"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/create_spot_sample(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_create_spot_sample_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"create_spot_sample"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/destination_warehouses(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_destination_warehouses_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"destination_warehouses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/icos/arrival_sample(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_icos_arrival_sample_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"icos"],[2,[7,"/"],[2,[6,"arrival_sample"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/icos/mill_sample(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_icos_mill_sample_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"icos"],[2,[7,"/"],[2,[6,"mill_sample"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/icos/spot_sample(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_icos_spot_sample_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"icos"],[2,[7,"/"],[2,[6,"spot_sample"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/spot_contracts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_spot_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"spot_contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/transport_samples/paginated_index(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_samples_transport_samples_paginated_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"transport_samples"],[2,[7,"/"],[2,[6,"paginated_index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/shipment_instructions/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_shipment_instruction_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"shipment_instructions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/shipping_advices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_shipping_advice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"shipping_advices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/transit_guides/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_transit_guide_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"transit_guides"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/warehouse_sampling_requests(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  api_v1_warehouse_sampling_requests_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"warehouse_sampling_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/approval(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  approval_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"approval"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/:id/approve_by_caravela(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  approve_by_caravela_fixed_price_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve_by_caravela"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/:id/approve_by_producer(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  approve_by_producer_fixed_price_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve_by_producer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:contract_id/contract_versions/:id/approve_reject(.:format)
   * @param {any} contract_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  approve_reject_contract_contract_version_path: __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"contract_versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve_reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /available_coffee(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  available_coffee_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"available_coffee"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /basedata/accepting_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_accepting_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"accepting_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/accepting_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_accepting_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"accepting_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/alternatives/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_alternative_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"alternatives"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/alternatives(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_alternatives_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"alternatives"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/approval_terms/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_approval_term_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"approval_terms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/approval_terms(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_approval_terms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"approval_terms"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/arbitrations/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_arbitration_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"arbitrations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/arbitrations(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_arbitrations_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"arbitrations"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/arrival_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_arrival_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"arrival_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/arrival_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_arrival_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"arrival_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/associations/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_association_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"associations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/associations(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_associations_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"associations"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/certificates/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_certificate_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"certificates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/certificates(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_certificates_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"certificates"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/certificates_prices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_certificates_price_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"certificates_prices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/certificates_prices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_certificates_prices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"certificates_prices"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/coffee_types/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_coffee_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"coffee_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/coffee_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_coffee_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"coffee_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/comments/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_comment_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"comments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/comments(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_comments_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"comments"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/company_sizes/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_company_size_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"company_sizes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/company_sizes(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_company_sizes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"company_sizes"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/consignees/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_consignee_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"consignees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/consignees(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_consignees_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"consignees"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/container_stuffings/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_container_stuffing_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"container_stuffings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/container_stuffings(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_container_stuffings_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"container_stuffings"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/container_types/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_container_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"container_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/container_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_container_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"container_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_closure_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_contract_closure_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_closure_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_closure_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_contract_closure_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_closure_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_prices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_contract_price_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_prices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_prices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_contract_prices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_prices"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_qualities(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_contract_qualities_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_qualities"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_qualities/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_contract_quality_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_qualities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_version_rejection_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_contract_version_rejection_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_version_rejection_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_version_rejection_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_contract_version_rejection_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_version_rejection_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/countries(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_countries_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"countries"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/countries/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_country_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/couriers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_courier_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"couriers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/couriers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_couriers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"couriers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/cupping_customers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_cupping_customer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"cupping_customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/cupping_customers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_cupping_customers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"cupping_customers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_customer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customer_sample_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_customer_sample_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customer_sample_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customer_sample_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_customer_sample_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customer_sample_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_customers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customs_brokers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_customs_broker_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customs_brokers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customs_brokers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_customs_brokers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customs_brokers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customs_officers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_customs_officer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customs_officers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customs_officers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_customs_officers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customs_officers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/dashboard(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_dashboard_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/decaf_plants/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_decaf_plant_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"decaf_plants"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/decaf_plants(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_decaf_plants_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"decaf_plants"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/defects/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_defect_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"defects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/defects(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_defects_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"defects"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/deltas/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_delta_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"deltas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/deltas(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_deltas_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"deltas"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/departments/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_department_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/departments(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_departments_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"departments"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_bodies(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_descriptor_bodies_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_bodies"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_bodies/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_descriptor_body_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_bodies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_flavours/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_descriptor_flavour_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_flavours"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_flavours(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_descriptor_flavours_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_flavours"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_overalls/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_descriptor_overall_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_overalls"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_overalls(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_descriptor_overalls_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_overalls"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_roasts/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_descriptor_roast_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_roasts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_roasts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_descriptor_roasts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_roasts"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/destination_warehouses/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_destination_warehouse_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"destination_warehouses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/destination_warehouses(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_destination_warehouses_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"destination_warehouses"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/docks/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_dock_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"docks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/docks(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_docks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"docks"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/drivers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_driver_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/drivers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_drivers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"drivers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/exchange_rates/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_exchange_rate_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"exchange_rates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/exchange_rates(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_exchange_rates_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"exchange_rates"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/exports_packing_declaration_configurations/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_exports_packing_declaration_configuration_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"exports_packing_declaration_configurations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/exports_packing_declaration_configurations(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_exports_packing_declaration_configurations_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"exports_packing_declaration_configurations"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/farms/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_farm_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"farms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/farm_certificate_quotas/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_farm_certificate_quota_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"farm_certificate_quotas"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/farm_certificate_quotas(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_farm_certificate_quotas_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"farm_certificate_quotas"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/farms(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_farms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"farms"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fermentation_types/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_fermentation_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fermentation_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fermentation_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_fermentation_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fermentation_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fiscal_id_types/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_fiscal_id_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fiscal_id_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fiscal_id_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_fiscal_id_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fiscal_id_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fraction_change_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_fraction_change_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fraction_change_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fraction_change_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_fraction_change_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fraction_change_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/freights/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_freight_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"freights"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/freights(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_freights_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"freights"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/general_setting(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_general_setting_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"general_setting"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/grades/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_grade_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"grades"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/grade_ranges/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_grade_range_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"grade_ranges"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/grades(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_grades_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"grades"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/import_markups/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_import_markup_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"import_markups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/import_markups(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_import_markups_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"import_markups"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lead_sources/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_lead_source_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lead_sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lead_sources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_lead_sources_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lead_sources"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lost_opportunity_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_lost_opportunity_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lost_opportunity_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lost_opportunity_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_lost_opportunity_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lost_opportunity_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lot_types/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_lot_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lot_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lot_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_lot_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lot_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/marks/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_mark_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"marks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/market_types/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_market_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"market_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/market_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_market_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"market_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/marks(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_marks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"marks"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/milling_machines/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_milling_machine_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"milling_machines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/milling_machines(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_milling_machines_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"milling_machines"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/municipalities(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_municipalities_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"municipalities"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/municipalities/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_municipality_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"municipalities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/norms/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_norm_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"norms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/norms(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_norms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"norms"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/notifies(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_notifies_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"notifies"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/notifies/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_notify_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"notifies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/offices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_office_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/offices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_offices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"offices"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/opportunity_sources/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_opportunity_source_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"opportunity_sources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/opportunity_sources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_opportunity_sources_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"opportunity_sources"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/packaging_types/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_packaging_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"packaging_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/packaging_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_packaging_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"packaging_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/payment_terms/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_payment_term_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"payment_terms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/payment_terms(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_payment_terms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"payment_terms"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/permissions/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_permission_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"permissions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/permissions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_permissions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"permissions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/personal_id_types/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_personal_id_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"personal_id_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/personal_id_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_personal_id_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"personal_id_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/ports/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_port_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"ports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/ports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_ports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"ports"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/preparations/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_preparation_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"preparations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/preparations(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_preparations_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"preparations"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/price_variables/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_price_variable_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"price_variables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/price_variables(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_price_variables_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"price_variables"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/process_types(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_process_types_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"process_types"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/process_types_prices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_process_types_price_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"process_types_prices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/process_types_prices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_process_types_prices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"process_types_prices"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/producers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_producer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"producers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/producers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_producers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"producers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/qualifications/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_qualification_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"qualifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/qualifications(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_qualifications_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"qualifications"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/qualities(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_qualities_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"qualities"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/qualities/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_quality_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"qualities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/reception_difference_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_reception_difference_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"reception_difference_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/reception_difference_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_reception_difference_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"reception_difference_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/rejecting_reasons/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_rejecting_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"rejecting_reasons"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/rejecting_reasons(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_rejecting_reasons_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"rejecting_reasons"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/release_terms/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_release_term_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"release_terms"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/release_terms(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_release_terms_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"release_terms"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roasting_results/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_roasting_result_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roasting_results"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roasting_results(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_roasting_results_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roasting_results"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roles/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_role_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roles(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_roles_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_root_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_lines/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_shipping_line_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_line_contracts/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_shipping_line_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_line_contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_line_contracts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_shipping_line_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_line_contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_lines(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_shipping_lines_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_modes/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_shipping_mode_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_modes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_modes(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_shipping_modes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_modes"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_variables/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_shipping_variable_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_variables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_variables(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_shipping_variables_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_variables"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/special_marks/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_special_mark_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"special_marks"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/special_marks(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_special_marks_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"special_marks"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/specialty_grading_specifications/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_specialty_grading_specification_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"specialty_grading_specifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/specialty_grading_specifications(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_specialty_grading_specifications_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"specialty_grading_specifications"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/standards/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_standard_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"standards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/standards(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_standards_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"standards"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/third_party_shippers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_third_party_shipper_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"third_party_shippers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/third_party_shippers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_third_party_shippers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"third_party_shippers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/transport_companies(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_transport_companies_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"transport_companies"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/transport_companies/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_transport_company_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"transport_companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/users/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_user_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/users(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_users_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/varieties(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_varieties_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"varieties"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/varieties_prices/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_varieties_price_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"varieties_prices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/varieties_prices(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_varieties_prices_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"varieties_prices"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/varieties/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_variety_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"varieties"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/warehouses/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_warehouse_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/warehouses(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  basedata_warehouses_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"warehouses"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /blocked_lots(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  blocked_lots_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blocked_lots"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /bundles/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  bundle_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /bundles(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  bundles_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"bundles"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /calibration(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calibration_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"calibration"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /calibration/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  calibration_calibrate_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"calibration"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:id/change_reason(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  change_reason_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_reason"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /opportunities/:id/change_sales_representative(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  change_sales_representative_opportunity_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"opportunities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"change_sales_representative"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/:id/clone(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  clone_fixed_price_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /opportunities/:id/clone(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  clone_opportunity_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"opportunities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/decaf/decaf_orders/:id/close(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  close_api_v1_decaf_decaf_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"decaf_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"close"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/third_party_imports/close(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  close_api_v1_exports_third_party_imports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"third_party_imports"],[2,[7,"/"],[2,[6,"close"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:id/close(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  close_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"close"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/milling_orders/:id/coffee_location_check(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  coffee_location_check_api_v1_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"coffee_location_check"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/consolidate_icos_opportunities(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  consolidate_icos_opportunities_api_v1_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"consolidate_icos_opportunities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/consolidate(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  consolidate_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"consolidate"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /contact_infos/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  contact_info_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contact_infos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:contract_id/contract_versions/:id(.:format)
   * @param {any} contract_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  contract_contract_version_path: __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"contract_versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /traceability/contracts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  contracts_traceability_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"traceability"],[2,[7,"/"],[2,[6,"contracts"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /samples/create_consolidate(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  create_consolidate_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"create_consolidate"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/create_for_ico(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  create_for_ico_api_v1_exports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"create_for_ico"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/ico_imports/create_or_update(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  create_or_update_api_v1_exports_ico_imports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"ico_imports"],[2,[7,"/"],[2,[6,"create_or_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /cupping_tables/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cupping_table_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cupping_tables"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /cupping_tables(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  cupping_tables_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cupping_tables"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /customers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  customer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /customer_leads/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  customer_lead_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_leads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /customer_leads(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  customer_leads_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_leads"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /customer_sample_dashboard(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  customer_sample_dashboard_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_sample_dashboard"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /customers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  customers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /cupping_tables/dashboard(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  dashboard_cupping_tables_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cupping_tables"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/decaf_orders/:decaf_order_id/physical_analysis(.:format)
   * @param {any} decaf_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  decaf_decaf_order_physical_analysis_path: __jsr.r({"decaf_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"decaf_orders"],[2,[7,"/"],[2,[3,"decaf_order_id"],[2,[7,"/"],[2,[6,"physical_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/quality_assurance(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  decaf_quality_assurance_index_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"quality_assurance"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/tail(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  decaf_tail_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"tail"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/transports/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  decaf_transport_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/transports/:transport_id/transport_leg(.:format)
   * @param {any} transport_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  decaf_transport_transport_leg_path: __jsr.r({"transport_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"transport_leg"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/transports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  decaf_transports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"transports"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/decaf_orders/:id/decaffeinate(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  decaffeinate_decaf_decaf_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"decaf_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"decaffeinate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/:id/delivery_order_address(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  delivery_order_address_api_v1_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delivery_order_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/:id/delivery_order_costs(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  delivery_order_costs_api_v1_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delivery_order_costs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/importer_security_fillings/destination_warehouses(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  destination_warehouses_api_v1_export_importer_security_fillings_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"importer_security_fillings"],[2,[7,"/"],[2,[6,"destination_warehouses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /logout(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  destroy_user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/business/v1/customer/disable(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  disable_api_business_v1_customer_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"business"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"disable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/dispatched(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  dispatched_transports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[6,"dispatched"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /exports/:export_id/download/download_all(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_all_export_download_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"download"],[2,[7,"/"],[2,[6,"download_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/availability_notices/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_availability_notice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"availability_notices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/ico_lists/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_ico_list_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ico_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/importer_security_fillings/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_importer_security_filling_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"importer_security_fillings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/packing_declarations/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_packing_declaration_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"packing_declarations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/packing_lists/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_packing_list_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"packing_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/responsibility_letters/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_responsibility_letter_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"responsibility_letters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/shipment_instructions/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_shipment_instruction_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"shipment_instructions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/shipping_advices/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_shipping_advice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"shipping_advices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/transit_guides/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_api_v1_transit_guide_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"transit_guides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:id/download(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  download_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:id/label_dry_reception(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  dry_lot_reception_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"label_dry_reception"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roles/:id/duplicate(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  duplicate_basedata_role_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"duplicate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_api_v1_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/accepting_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_accepting_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"accepting_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/alternatives/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_alternative_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"alternatives"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/approval_terms/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_approval_term_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"approval_terms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/arbitrations/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_arbitration_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"arbitrations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/arrival_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_arrival_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"arrival_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/associations/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_association_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"associations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/certificates/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_certificate_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"certificates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/certificates_prices/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_certificates_price_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"certificates_prices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/coffee_types/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_coffee_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"coffee_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/company_sizes/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_company_size_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"company_sizes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/consignees/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_consignee_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"consignees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/container_stuffings/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_container_stuffing_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"container_stuffings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/container_types/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_container_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"container_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_closure_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_contract_closure_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_closure_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_prices/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_contract_price_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_prices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_qualities/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_contract_quality_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_qualities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_version_rejection_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_contract_version_rejection_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_version_rejection_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/countries/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_country_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/couriers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_courier_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"couriers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/cupping_customers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_cupping_customer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"cupping_customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_customer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customer_sample_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_customer_sample_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customer_sample_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customs_brokers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_customs_broker_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customs_brokers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customs_officers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_customs_officer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customs_officers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/decaf_plants/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_decaf_plant_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"decaf_plants"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/defects/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_defect_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"defects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/deltas/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_delta_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"deltas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/departments/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_department_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_bodies/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_descriptor_body_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_bodies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_flavours/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_descriptor_flavour_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_flavours"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_overalls/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_descriptor_overall_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_overalls"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_roasts/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_descriptor_roast_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_roasts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/destination_warehouses/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_destination_warehouse_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"destination_warehouses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/docks/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_dock_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"docks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/drivers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_driver_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/exchange_rates/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_exchange_rate_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"exchange_rates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/exports_packing_declaration_configurations/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_exports_packing_declaration_configuration_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"exports_packing_declaration_configurations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/farm_certificate_quotas/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_farm_certificate_quota_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"farm_certificate_quotas"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fermentation_types/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_fermentation_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fermentation_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fiscal_id_types/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_fiscal_id_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fiscal_id_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fraction_change_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_fraction_change_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fraction_change_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/freights/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_freight_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"freights"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/general_setting/edit(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_general_setting_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"general_setting"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/grades/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_grade_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"grades"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/import_markups/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_import_markup_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"import_markups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lead_sources/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_lead_source_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lead_sources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lost_opportunity_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_lost_opportunity_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lost_opportunity_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lot_types/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_lot_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lot_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/marks/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_mark_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"marks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/market_types/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_market_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"market_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/milling_machines/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_milling_machine_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"milling_machines"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/municipalities/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_municipality_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"municipalities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/norms/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_norm_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"norms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/notifies/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_notify_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"notifies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/offices/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_office_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/opportunity_sources/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_opportunity_source_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"opportunity_sources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/packaging_types/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_packaging_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"packaging_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/payment_terms/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_payment_term_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"payment_terms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/permissions/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_permission_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"permissions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/personal_id_types/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_personal_id_type_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"personal_id_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/ports/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_port_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"ports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/preparations/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_preparation_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"preparations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/price_variables/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_price_variable_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"price_variables"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/process_types_prices/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_process_types_price_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"process_types_prices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/producers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_producer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"producers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/qualifications/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_qualification_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"qualifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/qualities/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_quality_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"qualities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/reception_difference_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_reception_difference_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"reception_difference_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/rejecting_reasons/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_rejecting_reason_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"rejecting_reasons"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/release_terms/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_release_term_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"release_terms"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roasting_results/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_roasting_result_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roasting_results"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roles/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_role_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_lines/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_shipping_line_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_lines"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_line_contracts/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_shipping_line_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_line_contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_modes/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_shipping_mode_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_modes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_variables/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_shipping_variable_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_variables"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/special_marks/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_special_mark_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"special_marks"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/specialty_grading_specifications/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_specialty_grading_specification_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"specialty_grading_specifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/standards/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_standard_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"standards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/third_party_shippers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_third_party_shipper_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"third_party_shippers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/transport_companies/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_transport_company_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"transport_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/users/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_user_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/varieties_prices/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_varieties_price_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"varieties_prices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/varieties/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_variety_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"varieties"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/warehouses/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_basedata_warehouse_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /cupping_tables/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_cupping_table_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cupping_tables"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /customers/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_customer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /customer_leads/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_customer_lead_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customer_leads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/decaf_orders/:decaf_order_id/physical_analysis/edit(.:format)
   * @param {any} decaf_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_decaf_decaf_order_physical_analysis_path: __jsr.r({"decaf_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"decaf_orders"],[2,[7,"/"],[2,[3,"decaf_order_id"],[2,[7,"/"],[2,[6,"physical_analysis"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_fixed_price_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /fulfillment/delivery_orders/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/physical_analysis/edit(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_lot_physical_analysis_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"physical_analysis"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/wet_physical_analysis/edit(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_lot_wet_physical_analysis_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"wet_physical_analysis"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/milling_outputs/:milling_output_id/machine_physical_analysis/edit(.:format)
   * @param {any} milling_order_id
   * @param {any} milling_output_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_milling_order_milling_output_machine_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"milling_output_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"milling_outputs"],[2,[7,"/"],[2,[3,"milling_output_id"],[2,[7,"/"],[2,[6,"machine_physical_analysis"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/samples/:sample_id/excelso_physical_analysis/:id/edit(.:format)
   * @param {any} milling_order_id
   * @param {any} sample_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_milling_order_sample_excelso_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"sample_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"excelso_physical_analysis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/samples/:sample_id/peabies_physical_analysis/:id/edit(.:format)
   * @param {any} milling_order_id
   * @param {any} sample_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_milling_order_sample_peabies_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"sample_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"peabies_physical_analysis"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /opportunities/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_opportunity_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"opportunities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /opportunities/:opportunity_id/contracts/:id/edit(.:format)
   * @param {any} opportunity_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_opportunity_contract_path: __jsr.r({"opportunity_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"opportunities"],[2,[7,"/"],[2,[3,"opportunity_id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /production_orders/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_production_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"production_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_rails_conductor_inbound_email_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/:sample_id/physical_analyses/edit(.:format)
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_sample_physical_analyses_path: __jsr.r({"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"physical_analyses"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /sensorial_analyses/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_sensorial_analysis_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sensorial_analyses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /subproducts/production_orders/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_subproducts_production_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subproducts"],[2,[7,"/"],[2,[6,"production_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:id/edit(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_transport_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:transport_id/transport_legs/:id/edit(.:format)
   * @param {any} transport_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  edit_transport_transport_leg_path: __jsr.r({"transport_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"transport_legs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/business/v1/customer/enable(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  enable_api_business_v1_customer_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"business"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"enable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /exports/:export_id/transport(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  export_transport_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"transport"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /exports/:export_id/transport/transport_leg(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  export_transport_transport_leg_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[6,"transport_leg"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /exports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  exports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"exports"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /fake_contracts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fake_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fake_contracts"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /fake_data(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fake_data_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fake_data"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /fake_login(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fake_login_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fake_login"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/v1/milling_orders/:id/finalise(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalise_api_v1_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalise"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/availability_notices/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_availability_notice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"availability_notices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/ico_lists/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_ico_list_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"ico_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/importer_security_fillings/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_importer_security_filling_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"importer_security_fillings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/packing_declarations/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_packing_declaration_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"packing_declarations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/packing_lists/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_packing_list_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"packing_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/responsibility_letters/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_responsibility_letter_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"responsibility_letters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/shipment_instructions/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_shipment_instruction_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"shipment_instructions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/shipping_advices/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_shipping_advice_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"shipping_advices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/transit_guides/:id/finalize(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finalize_api_v1_transit_guide_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"transit_guides"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finalize"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /cupping_tables/:id/finish_table(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  finish_table_cupping_table_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cupping_tables"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"finish_table"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fixed_price_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fixed_price_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:id/fractionate(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fractionate_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fractionate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /fulfillment/delivery_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /fulfillment/delivery_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fulfillment_delivery_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /fulfillment(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  fulfillment_root_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fulfillment"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /futures(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  futures_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"futures"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /contracts/:contract_id/contract_versions/:id/generate_documents(.:format)
   * @param {any} contract_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  generate_documents_contract_contract_version_path: __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"contract_versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_documents"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /sensorial_analyses/horizontal_session(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  horizontal_session_sensorial_analyses_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sensorial_analyses"],[2,[7,"/"],[2,[6,"horizontal_session"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /icos/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  ico_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"icos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /exports/ico_imports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  ico_imports_exports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"ico_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /icos(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  icos_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"icos"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /traceability/icos(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  icos_traceability_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"traceability"],[2,[7,"/"],[2,[6,"icos"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/contracts/import_markup(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  import_markup_api_v1_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"import_markup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/:id/in_progress(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  in_progress_api_v1_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"in_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/in_progress(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  in_progress_transports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[6,"in_progress"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /inventory_reconciliations(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  inventory_reconciliations_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"inventory_reconciliations"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/:id/invoice(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  invoice_api_v1_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invoice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/:id/label(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  label_sample_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"label"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/authorize_return(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_authorize_return_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"authorize_return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/humidity_analysis(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_humidity_analysis_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"humidity_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/physical_analysis(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_physical_analysis_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"physical_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/returned(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_returned_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"returned"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/roasting(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_roasting_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"roasting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/lot_set_sources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_set_sources_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"lot_set_sources"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /lot_sets(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_sets_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lot_sets"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /traceability/lot_sets(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_sets_traceability_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"traceability"],[2,[7,"/"],[2,[6,"lot_sets"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /samples/lot_sources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_sources_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"lot_sources"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/wet_physical_analysis(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lot_wet_physical_analysis_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"wet_physical_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lots_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lots"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /traceability/lots(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  lots_traceability_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"traceability"],[2,[7,"/"],[2,[6,"lots"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:id/mark_for_cupping(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  mark_for_cupping_api_v1_sample_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_for_cupping"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /marking_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  marking_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"marking_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /marking_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  marking_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"marking_orders"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/fractions(.:format)
   * @param {any} milling_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_order_fractions_path: __jsr.r({"milling_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"fractions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/milling_outputs/:milling_output_id/machine_physical_analysis(.:format)
   * @param {any} milling_order_id
   * @param {any} milling_output_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_order_milling_output_machine_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"milling_output_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"milling_outputs"],[2,[7,"/"],[2,[3,"milling_output_id"],[2,[7,"/"],[2,[6,"machine_physical_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/production_logs(.:format)
   * @param {any} milling_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_order_production_logs_path: __jsr.r({"milling_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"production_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/samples/:sample_id/excelso_physical_analysis/:id(.:format)
   * @param {any} milling_order_id
   * @param {any} sample_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_order_sample_excelso_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"sample_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"excelso_physical_analysis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/samples/:sample_id/excelso_physical_analysis(.:format)
   * @param {any} milling_order_id
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_order_sample_excelso_physical_analysis_index_path: __jsr.r({"milling_order_id":{"r":true},"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"excelso_physical_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/samples/:sample_id/peabies_physical_analysis/:id(.:format)
   * @param {any} milling_order_id
   * @param {any} sample_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_order_sample_peabies_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"sample_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"peabies_physical_analysis"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/samples/:sample_id/peabies_physical_analysis(.:format)
   * @param {any} milling_order_id
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_order_sample_peabies_physical_analysis_index_path: __jsr.r({"milling_order_id":{"r":true},"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"peabies_physical_analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/v1/milling_orders/:id/milling_outputs_info(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  milling_outputs_info_api_v1_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"milling_outputs_info"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /millings/close_marking_order(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_close_marking_order_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"close_marking_order"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /millings/ico_orphans/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_ico_orphan_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"ico_orphans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /millings/ico_orphans(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_ico_orphans_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"ico_orphans"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /millings/production_candidates(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_production_candidates_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"production_candidates"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /millings/production_log(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_production_log_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"production_log"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /millings/production_tail(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_production_tail_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"production_tail"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /millings/send_production_order(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_send_production_order_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"send_production_order"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /millings/subproduct_returns(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_subproduct_returns_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"subproduct_returns"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /traceability/millings(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  millings_traceability_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"traceability"],[2,[7,"/"],[2,[6,"millings"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/move_ico(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  move_ico_api_v1_exports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"move_ico"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_api_v1_fulfillment_delivery_order_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/accepting_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_accepting_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"accepting_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/alternatives/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_alternative_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"alternatives"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/approval_terms/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_approval_term_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"approval_terms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/arbitrations/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_arbitration_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"arbitrations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/arrival_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_arrival_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"arrival_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/associations/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_association_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"associations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/certificates/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_certificate_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"certificates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/certificates_prices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_certificates_price_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"certificates_prices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/coffee_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_coffee_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"coffee_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/company_sizes/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_company_size_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"company_sizes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/consignees/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_consignee_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"consignees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/container_stuffings/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_container_stuffing_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"container_stuffings"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/container_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_container_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"container_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_closure_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_contract_closure_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_closure_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_prices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_contract_price_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_prices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_qualities/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_contract_quality_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_qualities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/contract_version_rejection_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_contract_version_rejection_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"contract_version_rejection_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/countries/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_country_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"countries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/couriers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_courier_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"couriers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/cupping_customers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_cupping_customer_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"cupping_customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customer_sample_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_customer_sample_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customer_sample_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customs_brokers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_customs_broker_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customs_brokers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/customs_officers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_customs_officer_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"customs_officers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/decaf_plants/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_decaf_plant_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"decaf_plants"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/defects/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_defect_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"defects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/deltas/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_delta_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"deltas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/departments/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_department_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_bodies/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_descriptor_body_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_bodies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_flavours/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_descriptor_flavour_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_flavours"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_overalls/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_descriptor_overall_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_overalls"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/descriptor_roasts/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_descriptor_roast_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"descriptor_roasts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/destination_warehouses/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_destination_warehouse_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"destination_warehouses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/docks/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_dock_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"docks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/drivers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_driver_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"drivers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/exports_packing_declaration_configurations/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_exports_packing_declaration_configuration_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"exports_packing_declaration_configurations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/farm_certificate_quotas/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_farm_certificate_quota_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"farm_certificate_quotas"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fermentation_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_fermentation_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fermentation_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fiscal_id_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_fiscal_id_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fiscal_id_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/fraction_change_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_fraction_change_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"fraction_change_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/freights/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_freight_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"freights"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/grades/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_grade_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"grades"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/import_markups/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_import_markup_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"import_markups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lead_sources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_lead_source_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lead_sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lost_opportunity_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_lost_opportunity_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lost_opportunity_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/lot_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_lot_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"lot_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/marks/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_mark_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"marks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/market_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_market_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"market_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/milling_machines/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_milling_machine_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"milling_machines"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/municipalities/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_municipality_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"municipalities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/norms/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_norm_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"norms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/notifies/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_notify_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"notifies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/offices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_office_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"offices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/opportunity_sources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_opportunity_source_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"opportunity_sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/packaging_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_packaging_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"packaging_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/payment_terms/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_payment_term_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"payment_terms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/permissions/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_permission_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"permissions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/personal_id_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_personal_id_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"personal_id_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/ports/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_port_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"ports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/preparations/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_preparation_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"preparations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/price_variables/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_price_variable_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"price_variables"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/process_types/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_process_type_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"process_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/process_types_prices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_process_types_price_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"process_types_prices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/qualifications/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_qualification_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"qualifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/qualities/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_quality_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"qualities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/reception_difference_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_reception_difference_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"reception_difference_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/rejecting_reasons/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_rejecting_reason_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"rejecting_reasons"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/release_terms/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_release_term_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"release_terms"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roasting_results/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_roasting_result_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roasting_results"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/roles/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_role_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_lines/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_shipping_line_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_lines"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_line_contracts/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_shipping_line_contract_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_line_contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_modes/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_shipping_mode_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_modes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/shipping_variables/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_shipping_variable_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"shipping_variables"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/special_marks/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_special_mark_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"special_marks"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/standards/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_standard_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"standards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/third_party_shippers/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_third_party_shipper_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"third_party_shippers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/transport_companies/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_transport_company_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"transport_companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/users/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_user_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/varieties_prices/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_varieties_price_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"varieties_prices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/varieties/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_variety_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"varieties"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /basedata/warehouses/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_basedata_warehouse_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basedata"],[2,[7,"/"],[2,[6,"warehouses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_contract_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /cupping_tables/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_cupping_table_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cupping_tables"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /customers/new(/:lead_id)(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_customer_path: __jsr.r({"lead_id":{},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[2,[1,[2,[7,"/"],[3,"lead_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]),

  /**
   * Generates rails route to
   * /customer_leads/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_customer_lead_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customer_leads"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/decaf_orders/:decaf_order_id/physical_analysis/new(.:format)
   * @param {any} decaf_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_decaf_decaf_order_physical_analysis_path: __jsr.r({"decaf_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"decaf_orders"],[2,[7,"/"],[2,[3,"decaf_order_id"],[2,[7,"/"],[2,[6,"physical_analysis"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/transports/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_decaf_transport_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/transports/:transport_id/transport_leg/new(.:format)
   * @param {any} transport_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_decaf_transport_transport_leg_path: __jsr.r({"transport_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"transport_leg"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /exports/:export_id/transport/new(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_export_transport_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /exports/:export_id/transport/transport_leg/new(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_export_transport_transport_leg_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[6,"transport_leg"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_fixed_price_contract_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /fulfillment/delivery_orders/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_fulfillment_delivery_order_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_lot_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/physical_analysis/new(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_lot_physical_analysis_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"physical_analysis"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:lot_id/wet_physical_analysis/new(.:format)
   * @param {any} lot_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_lot_wet_physical_analysis_path: __jsr.r({"lot_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"wet_physical_analysis"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/milling_outputs/:milling_output_id/machine_physical_analysis/new(.:format)
   * @param {any} milling_order_id
   * @param {any} milling_output_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_milling_order_milling_output_machine_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"milling_output_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"milling_outputs"],[2,[7,"/"],[2,[3,"milling_output_id"],[2,[7,"/"],[2,[6,"machine_physical_analysis"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/samples/:sample_id/excelso_physical_analysis/new(.:format)
   * @param {any} milling_order_id
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_milling_order_sample_excelso_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"excelso_physical_analysis"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:milling_order_id/samples/:sample_id/peabies_physical_analysis/new(.:format)
   * @param {any} milling_order_id
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_milling_order_sample_peabies_physical_analysis_path: __jsr.r({"milling_order_id":{"r":true},"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"peabies_physical_analysis"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /opportunities/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_opportunity_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"opportunities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /opportunities/:opportunity_id/contracts/new(.:format)
   * @param {any} opportunity_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_opportunity_contract_path: __jsr.r({"opportunity_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"opportunities"],[2,[7,"/"],[2,[3,"opportunity_id"],[2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /production_orders/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_production_order_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"production_orders"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /production_orders/new(/:ico_id)(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_production_orders_path: __jsr.r({"ico_id":{},"format":{}}, [2,[7,"/"],[2,[6,"production_orders"],[2,[7,"/"],[2,[6,"new"],[2,[1,[2,[7,"/"],[3,"ico_id"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_rails_conductor_inbound_email_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_rails_conductor_inbound_email_source_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_sample_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /samples/:sample_id/physical_analyses/new(.:format)
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_sample_physical_analysis_path: __jsr.r({"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"physical_analyses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/transport/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_sample_transport_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/transport/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_sample_transports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /sensorial_analyses/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_sensorial_analysis_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sensorial_analyses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /transports/new(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_transport_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:transport_id/transport_legs/new(.:format)
   * @param {any} transport_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_transport_transport_leg_path: __jsr.r({"transport_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"transport_legs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:transport_id/transport_legs/new(.:format)
   * @param {any} transport_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_transport_transport_legs_path: __jsr.r({"transport_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"transport_legs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /login(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  new_user_session_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/offer_sample_sources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  offer_sample_sources_api_v1_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"offer_sample_sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /opportunities(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  opportunities_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"opportunities"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /opportunities/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  opportunity_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"opportunities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/outgoing(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  outgoing_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[6,"outgoing"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/paginated_index(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  paginated_index_api_v1_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"paginated_index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/pending(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  pending_fixed_price_contracts_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /pending_random_samples(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  pending_random_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pending_random_samples"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /pending_random_samples/:id/create(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  pending_random_samples_create_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pending_random_samples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /pending_random_samples/:id/discard(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  pending_random_samples_discard_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"pending_random_samples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"discard"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /pending_random_samples/generate(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  pending_random_samples_generate_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"pending_random_samples"],[2,[7,"/"],[2,[6,"generate"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /cupping_tables/pending_tables(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  pending_tables_cupping_tables_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cupping_tables"],[2,[7,"/"],[2,[6,"pending_tables"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:contract_id/contract_versions/:id/preview(.:format)
   * @param {any} contract_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  preview_contract_contract_version_path: __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"contract_versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /fulfillment/delivery_orders/:id/preview(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  preview_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/transports/:id/print(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_decaf_transport_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /exports/:export_id/transport/print(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_export_transport_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/:id/print(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_fixed_price_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /icos/:id/print_liquidation_information(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_liquidation_information_ico_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"icos"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_liquidation_information"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:id/print_producer_receipt(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_producer_receipt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:id/print_purchase_price_approval(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_purchase_price_approval_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_purchase_price_approval"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:id/print_storage(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_storage_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_storage"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lot_sets/:id/print_storage(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_storage_lot_set_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lot_sets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_storage"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:transport_id/transport_legs/:id/print(.:format)
   * @param {any} transport_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_transport_transport_leg_path: __jsr.r({"transport_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"transport_legs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:id/print_wet_coffee_reception(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_wet_coffee_reception_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_wet_coffee_reception"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/:id/print_wet_coffee_return_order(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  print_wet_coffee_return_order_lot_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"print_wet_coffee_return_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:id/produce(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  produce_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"produce"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /production_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  production_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"production_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /production_orders/:production_order_id/assign_coffee(.:format)
   * @param {any} production_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  production_order_assign_coffee_path: __jsr.r({"production_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"production_orders"],[2,[7,"/"],[2,[3,"production_order_id"],[2,[7,"/"],[2,[6,"assign_coffee"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /production_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  production_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"production_orders"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:id/qa(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  qa_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"qa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:id/qualify(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  qualify_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"qualify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /sensorial_analyses/quality(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  quality_sensorial_analyses_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sensorial_analyses"],[2,[7,"/"],[2,[6,"quality"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
   * @param {any} signed_blob_id
   * @param {any} variation_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_blob_representation_path: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
   * @param {any} signed_blob_id
   * @param {any} variation_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_blob_representation_proxy_path: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
   * @param {any} inbound_email_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_reroute_path: __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_email_sources_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/conductor/action_mailbox/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_conductor_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/direct_uploads(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_direct_uploads_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/disk/:encoded_key/*filename(.:format)
   * @param {any} encoded_key
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_disk_service_path: __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/info(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_info_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /rails/info/properties(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_info_properties_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/info/routes(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_info_routes_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/mailers(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mailers_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mailgun_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mandrill/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mandrill_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/mandrill/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_mandrill_inbound_health_check_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/postmark/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_postmark_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/relay/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_relay_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_sendgrid_inbound_emails_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
   * @param {any} signed_id
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_service_blob_path: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
   * @param {any} signed_id
   * @param {any} filename
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rails_service_blob_proxy_path: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/transport/receive(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  receive_sample_transports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[6,"receive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:id/refresh_signatures(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  refresh_signatures_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refresh_signatures"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:id/regrade(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  regrade_api_v1_sample_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"regrade"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /milling_orders/:id/reject(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  reject_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reject"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /lots/rejected(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  rejected_lots_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[6,"rejected"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /report(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  report_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"report"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/v1/lots/:lot_id/final_prices/:id/reprice(.:format)
   * @param {any} lot_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  reprice_api_v1_lot_final_price_path: __jsr.r({"lot_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"lots"],[2,[7,"/"],[2,[3,"lot_id"],[2,[7,"/"],[2,[6,"final_prices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"reprice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/milling_orders/:id/request_orphan_ico(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  request_orphan_ico_api_v1_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_orphan_ico"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:contract_id/contract_versions/:id/request_signature(.:format)
   * @param {any} contract_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  request_signature_contract_contract_version_path: __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"contract_versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"request_signature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /contracts/:id/retrieve_pdf_signature(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  retrieve_pdf_signature_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"retrieve_pdf_signature"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /millings/subproduct_returns/return(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  return_millings_subproduct_returns_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"millings"],[2,[7,"/"],[2,[6,"subproduct_returns"],[2,[7,"/"],[2,[6,"return"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /
   * @param {object | undefined} options
   * @returns {string} route path
   */
  root_path: __jsr.r({}, [7,"/"]),

  /**
   * Generates rails route to
   * /samples/sample_pending_opportunities(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sample_pending_opportunities_samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"sample_pending_opportunities"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /samples/:sample_id/physical_analyses(.:format)
   * @param {any} sample_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sample_physical_analyses_path: __jsr.r({"sample_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"physical_analyses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/:sample_id/physical_analyses/:id(.:format)
   * @param {any} sample_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sample_physical_analysis_path: __jsr.r({"sample_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"sample_id"],[2,[7,"/"],[2,[6,"physical_analyses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/transport/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sample_transport_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/transport/:sample_transport_id/close_transport(.:format)
   * @param {any} sample_transport_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sample_transport_close_transport_path: __jsr.r({"sample_transport_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[3,"sample_transport_id"],[2,[7,"/"],[2,[6,"close_transport"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /samples/transport(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sample_transports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[6,"transport"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /samples(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  samples_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"samples"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:export_id/shipping_advices/send_out(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  send_out_api_v1_export_shipping_advices_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"shipping_advices"],[2,[7,"/"],[2,[6,"send_out"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/:id/send_to_accounting(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  send_to_accounting_api_v1_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_to_accounting"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /sensorial_analyses(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sensorial_analyses_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sensorial_analyses"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /sensorial_analyses/fractions(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sensorial_analyses_fractions_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sensorial_analyses"],[2,[7,"/"],[2,[6,"fractions"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /sensorial_analyses/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sensorial_analysis_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sensorial_analyses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /services/adobe_sign/webhook(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  services_adobe_sign_webhook_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"adobe_sign"],[2,[7,"/"],[2,[6,"webhook"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /services/twilio/whatsapp_callback(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  services_twilio_whatsapp_callback_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"services"],[2,[7,"/"],[2,[6,"twilio"],[2,[7,"/"],[2,[6,"whatsapp_callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:id/set_destination_port(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  set_destination_port_api_v1_export_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_destination_port"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/set_export_date(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  set_export_date_api_v1_exports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"set_export_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/set_import_date(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  set_import_date_api_v1_exports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"set_import_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:id/set_invoice(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  set_invoice_api_v1_export_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_invoice"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /set_issueable(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  set_issueable_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"set_issueable"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /set_issueable_and_redirect(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  set_issueable_and_redirect_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"set_issueable_and_redirect"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:id/set_shipping_port(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  set_shipping_port_api_v1_export_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"set_shipping_port"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/fulfillment/delivery_orders/:id/shipping_options(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  shipping_options_api_v1_fulfillment_delivery_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"fulfillment"],[2,[7,"/"],[2,[6,"delivery_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"shipping_options"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /customers/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  show_customer_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /background_dashboard
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sidekiq_web_path: __jsr.r({}, [2,[7,"/"],[6,"background_dashboard"]]),

  /**
   * Generates rails route to
   * /contracts/:contract_id/contract_versions/:id/sign_agreement(.:format)
   * @param {any} contract_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sign_agreement_contract_contract_version_path: __jsr.r({"contract_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"contracts"],[2,[7,"/"],[2,[3,"contract_id"],[2,[7,"/"],[2,[6,"contract_versions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign_agreement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/:id/sign(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  sign_fixed_price_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/milling_orders/:id/start(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  start_api_v1_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"start"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /styleguide(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  styleguide_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"styleguide"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /subproducts/milling_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  subproducts_milling_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subproducts"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /subproducts/milling_orders/:milling_order_id/qa(.:format)
   * @param {any} milling_order_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  subproducts_milling_order_qa_path: __jsr.r({"milling_order_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subproducts"],[2,[7,"/"],[2,[6,"milling_orders"],[2,[7,"/"],[2,[3,"milling_order_id"],[2,[7,"/"],[2,[6,"qa"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /subproducts/milling_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  subproducts_milling_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subproducts"],[2,[7,"/"],[2,[6,"milling_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /subproducts/production_orders/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  subproducts_production_order_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"subproducts"],[2,[7,"/"],[2,[6,"production_orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /subproducts/production_orders(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  subproducts_production_orders_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subproducts"],[2,[7,"/"],[2,[6,"production_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /subproducts(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  subproducts_root_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"subproducts"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /switch_language/:language(.:format)
   * @param {any} language
   * @param {object | undefined} options
   * @returns {string} route path
   */
  switch_language_path: __jsr.r({"language":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"switch_language"],[2,[7,"/"],[2,[3,"language"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /exports/third_party_imports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  third_party_imports_exports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"third_party_imports"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /traceability(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  traceability_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"traceability"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /transports/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  transport_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:transport_id/transport_legs/:id(.:format)
   * @param {any} transport_id
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  transport_transport_leg_path: __jsr.r({"transport_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"transport_legs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:transport_id/transport_legs(.:format)
   * @param {any} transport_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  transport_transport_legs_path: __jsr.r({"transport_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"transport_legs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:transport_id/unload(.:format)
   * @param {any} transport_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  transport_unload_path: __jsr.r({"transport_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"unload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports/:transport_id/unloaded(.:format)
   * @param {any} transport_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  transport_unloaded_path: __jsr.r({"transport_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"transport_id"],[2,[7,"/"],[2,[6,"unloaded"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /transports(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  transports_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"transports"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /api/v1/decaf/transports/:id/unload(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  unload_api_v1_decaf_transport_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/transports/:id/unload(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  unload_api_v1_exports_transport_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /decaf/transports/:id/unload(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  unload_decaf_transport_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"decaf"],[2,[7,"/"],[2,[6,"transports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /exports/:export_id/transport/unload(.:format)
   * @param {any} export_id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  unload_export_transport_path: __jsr.r({"export_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"export_id"],[2,[7,"/"],[2,[6,"transport"],[2,[7,"/"],[2,[6,"unload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/business/v1/customer/update_payment_term(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_payment_term_api_business_v1_customer_path: __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"business"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"customer"],[2,[7,"/"],[2,[6,"update_payment_term"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /rails/active_storage/disk/:encoded_token(.:format)
   * @param {any} encoded_token
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_rails_disk_service_path: __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/samples/:id/update_tracking_note(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  update_tracking_note_api_v1_sample_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"samples"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_tracking_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /api/v1/exports/:id/upload(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  upload_api_v1_export_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"exports"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /fixed_price_contracts/:id/upload(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  upload_fixed_price_contract_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"fixed_price_contracts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/auth/azure_activedirectory_v2(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  user_azure_activedirectory_v2_omniauth_authorize_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"azure_activedirectory_v2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /users/auth/azure_activedirectory_v2/callback(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  user_azure_activedirectory_v2_omniauth_callback_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"azure_activedirectory_v2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  /**
   * Generates rails route to
   * /sensorial_analyses/vertical_session(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  vertical_session_sensorial_analyses_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sensorial_analyses"],[2,[7,"/"],[2,[6,"vertical_session"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  /**
   * Generates rails route to
   * /wet_reception(.:format)
   * @param {object | undefined} options
   * @returns {string} route path
   */
  wet_reception_path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"wet_reception"],[1,[2,[8,"."],[3,"format"]]]]]),

  /**
   * Generates rails route to
   * /wet_reception/set_lot_to_dry/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  wet_reception_set_dry_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"wet_reception"],[2,[7,"/"],[2,[6,"set_lot_to_dry"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /wet_reception/update_lot_drying/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  wet_reception_update_lot_drying_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"wet_reception"],[2,[7,"/"],[2,[6,"update_lot_drying"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  /**
   * Generates rails route to
   * /wet_reception/update_lot_location/:id(.:format)
   * @param {any} id
   * @param {object | undefined} options
   * @returns {string} route path
   */
  wet_reception_update_lot_location_path: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"wet_reception"],[2,[7,"/"],[2,[6,"update_lot_location"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]])}
,
    };
    Utils.define_module("UMD", result);
    return result;
})(this);
